import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    TextField,
    Backdrop,
    CircularProgress,
    Divider,
    IconButton,
    Card,
    CardContent,
    Grid
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import ProductPreviewController, { Props, Section, configJSON, IStaffData } from "./ProductPreviewScreenController.web";
import PlusIcon from '@material-ui/icons/Add';
import { GooglePlayIcon, sideArrow, sideArrow1, AppStore, twitterLogo, AddIcon, instagramLogo, facebookLogo, ModalSearchIcon, youtubLogo, quote, leftIcon, rightIcon, searchIcon, LocationIcon } from "./assets";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";
import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaffData } from "../../dashboard/src/StylistCarouselController.web";
import Skeleton from "@material-ui/lab/Skeleton";
import { StaffDummyImage } from "../../appointmentmanagement/src/assets";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import StarIcon from '@material-ui/icons/Star';

const PrevArrow: React.FC<CustomArrowProps> = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                left: -"8px",
                backgroundColor: "#222222",
                opacity: "80%",
                width: "60px",
                height: "60px",
                color: "white",
                borderRadius: "50px"
            }}
            onClick={onClick}
        >
            <img src={sideArrow1} />
        </div>
    );
};

const NextArrow: React.FC<CustomArrowProps> = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                right: -"8px",
                backgroundColor: "#222222",
                opacity: "80%",
                width: "60px",
                height: "60px",
                color: "white",
                borderRadius: "50px"
            }}
            onClick={onClick}
        >
            <img src={sideArrow} />
        </div>
    );
};

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
};
const settingsTopServices = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: false,
    draggable: false,
    swipe: false,
};
// Customizable Area End


class ProductPreviewDesktopScreen extends ProductPreviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    dynamicLogo() {
        return (
            <>
                {this.props.statesData.removeLogo ?
                    <Box style={{
                        fontFamily: `${this.props.statesData.textLogoMetaData.fontType}`,
                        fontSize: "25.24px",
                        fontWeight: 500,
                        letterSpacing: "-0.02em",
                        color: `${this.props.statesData.textLogoMetaData.colour}`
                    }}> {this.props.statesData?.storeName}</Box>
                    :
                    <>
                        {!this.props?.updateData?.header?.isStoreNameEnabled ? (
                            <img src={this.props?.updateData?.header?.selectedLogo} alt="Logo" height="34px" />
                        ) : (
                            <Box style={{
                                fontFamily: `${this.props.statesData.textLogoMetaData.fontType}`,
                                fontSize: 18,
                                fontWeight: 500,
                                letterSpacing: "-0.02em",
                                color: `${this.props.statesData.textLogoMetaData.colour}`,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                // textAlign: 'center',
                                width: '100%',
                                maxWidth: 115,
                            }} id="dynamic-logo"> {this.props.statesData?.storeName}</Box>
                        )}
                    </>
                }
            </>
        )
    }

    renderCustomHeader = (themeName: string, selectedColor: { secondary: string; primary: string; }, selectedFonts: { body: string; }) => {
        return (
            <>
                <DesktopPreviewHeader id="headerUnique" style={{ backgroundColor: themeName === "Nexus" ? "#222222" : "#ffffff" }}>
                    <HeaderIconAndNameHolder>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {!this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo && !this.props?.updateData?.header?.isStoreNameEnabled &&
                                <AddLogoBox>
                                    <AddLogoPlusIcon src={AddIcon} />
                                    <AddLogoText>Add Logo</AddLogoText>
                                </AddLogoBox>
                            }
                            {this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo &&
                                <img src={this.props?.updateData?.header?.selectedLogo} alt="Logo" height="auto" width='auto' style={{ maxHeight: "35px" }} />
                            }
                            {(this.props?.updateData?.header?.isLogoUploaded ? this.props?.updateData?.header?.isStoreNameEnabled : this.props.statesData?.storeName) &&
                                <Box sx={{ margin: '0 10px', fontWeight: '500', color: `${this.props.statesData.textLogoMetaData.colour}`, fontFamily: `${this.props.statesData.textLogoMetaData.fontType}`, fontSize: '12px' }}>
                                    {this.props.statesData?.storeName}
                                </Box>
                            }
                        </Box>
                        <HeaderContainerBox>
                            {
                                this.props.statesData?.navigationItems?.nevigationItem1 ?
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem1}</HeaderServiceText>
                                    </Box> :
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.services}</HeaderServiceText>
                                    </Box>
                            }
                            {
                                this.props.statesData?.navigationItems?.nevigationItem2 ?
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem2}</HeaderServiceText>
                                    </Box> :
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.serviceProviders}</HeaderServiceText>
                                    </Box>
                            }
                            {
                                this.props.statesData?.navigationItems?.nevigationItem3 ?
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem3}</HeaderServiceText>
                                    </Box> :
                                    <Box>
                                        <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.gallery}</HeaderServiceText>
                                    </Box>
                            }
                        </HeaderContainerBox>
                    </HeaderIconAndNameHolder>

                    <Box style={{ padding: "10px 0px", width: "50%", display: 'flex', alignItems: 'center', gap: '10px', justifyContent: "end" }}>
                        <TextField variant="outlined" placeholder="Search" style={{ borderRadius: '30px', width: "139.42px", fontFamily: selectedFonts.body }}
                            value=""
                            InputProps={{
                                style: { borderRadius: "4px", height: '18px', width: '100%' },
                                startAdornment: (<InputAdornment position="start"> <HeaderSearchIcon src={ModalSearchIcon} /> </InputAdornment>),
                            }} />
                        <ManageBooking style={{ backgroundColor: selectedColor.primary, fontFamily: selectedFonts.body }}>{configJSON.manageBooking}</ManageBooking>
                    </Box>
                </DesktopPreviewHeader>
            </>
        )
    }

    renderCustomTheamHeader = (themeName: string, selectedColor: { secondary: string; primary: string; }, selectedFonts: { body: string; }) => {
        return (
            <>
                <GridContainer container style={{ backgroundColor: themeName === "Radiance" ? "#222222" : "#ffffff" }}>
                    <Grid xs={4}></Grid>
                    <Grid xs={4}>
                        <Box className={"navigationContainer"}>
                            <Box className={"homeLink"}>
                                {!this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo && !this.props?.updateData?.header?.isStoreNameEnabled &&
                                    <AddLogoBox>
                                        <AddLogoPlusIcon src={AddIcon} />
                                        <AddLogoText>Add Logo</AddLogoText>
                                    </AddLogoBox>
                                }
                                {this.props?.updateData?.header?.isLogoUploaded && !this.props.statesData.removeLogo &&
                                    <img src={this.props?.updateData?.header?.selectedLogo} alt="Logo" height="auto" width='auto' style={{ maxHeight: "35px" }} />
                                }
                                {(this.props?.updateData?.header?.isLogoUploaded ? this.props?.updateData?.header?.isStoreNameEnabled : this.props.statesData?.storeName) &&
                                    <Box sx={{ margin: '0 10px', fontWeight: '500', color: `${this.props.statesData.textLogoMetaData.colour}`, fontFamily: `${this.props.statesData.textLogoMetaData.fontType}`, fontSize: '12px' }}>
                                        {this.props.statesData?.storeName}
                                    </Box>
                                }
                            </Box>
                            <HeaderContainer>
                                {
                                    this.props.statesData?.navigationItems?.nevigationItem1 ?
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem1}</HeaderServiceText>
                                        </Box> :
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.services}</HeaderServiceText>
                                        </Box>
                                }
                                {
                                    this.props.statesData?.navigationItems?.nevigationItem2 ?
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem2}</HeaderServiceText>
                                        </Box> :
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.serviceProviders}</HeaderServiceText>
                                        </Box>
                                }
                                {
                                    this.props.statesData?.navigationItems?.nevigationItem3 ?
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{this.props.statesData?.navigationItems.nevigationItem3}</HeaderServiceText>
                                        </Box> :
                                        <Box>
                                            <HeaderServiceText style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{configJSON.gallery}</HeaderServiceText>
                                        </Box>
                                }
                            </HeaderContainer>
                        </Box>
                    </Grid>

                    <Grid xs={4}>
                        <Box style={{ padding: "10px 15px", display: 'flex', alignItems: 'center', gap: '10px', justifyContent: "end" }}>
                            <img src={themeName === "Radiance" ? ModalSearchIcon : searchIcon} width={"15px"} height={"15px"} />
                            <ManageBooking style={{ backgroundColor: selectedColor.primary, fontFamily: selectedFonts.body }}>{configJSON.manageBooking}</ManageBooking>
                        </Box>
                    </Grid>
                </GridContainer>
            </>
        )

    }

    skeletonButton = () => {
        return (
            <>
                <Box style={webStyle.skeletonBox}>
                    <Skeleton variant="text" animation={false} width={75} height={10} style={webStyle.skeletonText} />
                    <Skeleton variant="rect" animation={false} width={100} height={35} style={webStyle.skeletonBtn} />
                </Box>
            </>
        )
    }

    renderBannerSkeleton = (index: number) => {
        return (
            <>
                <BannerContainer style={{ backgroundColor: "#E8E8E8" }} id={`section${index}Unique`}>
                    <Box style={webStyle.bannerSkeletonLayout as React.CSSProperties}>
                        <Skeleton animation="wave" width={213} style={webStyle.bannerSkeleton} />
                        <Skeleton animation="wave" width={240} style={webStyle.bannerSkeleton} />
                        <Skeleton animation="wave" width={125} style={webStyle.bannerSkeleton} />
                    </Box>
                    <BannerImageSkeleton>
                    </BannerImageSkeleton>
                </BannerContainer>
            </>
        )
    }

    renderServiceSkeleton = (selectedServices: Section[], index: number) => {
        return (
            <>
                <MainServiceBox>
                    <Skeleton animation="wave" height={10} width={100} style={webStyle.textSkeleton2} />
                    <Box className={"bodySection"}>
                        <Slider {...settingsTopServices} className={'sliderContainer'}>
                            {selectedServices?.filter((item: Section) => item.serviceAttrs?.id).map((service: Section, curElem: number) => (
                                <Card elevation={0} key={curElem} className="serviceCard">
                                    <CardContent className={"cardContent"}>
                                        <Box className={"imageBox"}>
                                            <Skeleton variant="rect" animation={false} width={200} height={"100%"} style={webStyle.serviceSkeleton} />
                                        </Box>
                                        {/* <Box className={"cardFooterConatiner"}>
                                            <Box>
                                                <Skeleton animation="wave" height={5} width={30} style={{ marginBottom: 6, background: "#757575" }} />
                                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#434343" }} />
                                            </Box>
                                            <Box>
                                                {this.skeletonButton()}
                                            </Box>
                                        </Box> */}
                                    </CardContent>
                                </Card>
                            ))}
                            <Card elevation={0} className="serviceCard">
                                <CardContent className={"cardContent"}>
                                    <Box className={"imageBox"}>
                                        <Skeleton variant="rect" animation={false} width={200} height={"100%"} style={webStyle.serviceSkeleton} />
                                    </Box>
                                    {/* <Box className={"cardFooterConatiner"}>
                                            <Box>
                                                <Skeleton animation="wave" height={5} width={30} style={{ marginBottom: 6, background: "#757575" }} />
                                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#434343" }} />
                                            </Box>
                                            <Box>
                                                {this.skeletonButton()}
                                            </Box>
                                        </Box> */}
                                </CardContent>
                            </Card>
                            <Card elevation={0} className="serviceCard">
                                <CardContent className={"cardContent"}>
                                    <Box className={"imageBox"}>
                                        <Skeleton variant="rect" animation={false} width={200} height={"100%"} style={webStyle.serviceSkeleton} />
                                    </Box>
                                    {/* <Box className={"cardFooterConatiner"}>
                                            <Box>
                                                <Skeleton animation="wave" height={5} width={30} style={{ marginBottom: 6, background: "#757575" }} />
                                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#434343" }} />
                                            </Box>
                                            <Box>
                                                {this.skeletonButton()}
                                            </Box>
                                        </Box> */}
                                </CardContent>
                            </Card>
                            <Card elevation={0} className="serviceCard">
                                <CardContent className={"cardContent"}>
                                    <Box className={"imageBox"}>
                                        <Skeleton variant="rect" animation={false} width={200} height={"100%"} style={webStyle.serviceSkeleton} />
                                    </Box>
                                    {/* <Box className={"cardFooterConatiner"}>
                                            <Box>
                                                <Skeleton animation="wave" height={5} width={30} style={{ marginBottom: 6, background: "#757575" }} />
                                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#434343" }} />
                                            </Box>
                                            <Box>
                                                {this.skeletonButton()}
                                            </Box>
                                        </Box> */}
                                </CardContent>
                            </Card>
                        </Slider>
                    </Box>
                    <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {this.skeletonButton()}
                    </Box>
                </MainServiceBox>
            </>
        )
    }

    renderStaffSkeleton = (section: { metadata: { staff: StaffData[] }; }, index: number) => {
        return (
            <>
                <Box style={webStyle.bodyContainerSkeleton} id={`section${index}Unique`}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{ gap: '30px', flexDirection: 'column' }}>
                        <Box padding={"0 20px"} width={'100%'}>
                            <Divider style={webStyle.staffSkeletonDivider} />
                        </Box>
                        <Box>
                            <Skeleton animation="wave" height={11} width={124} style={webStyle.textSkeleton} />

                        </Box>

                    </Box>
                    <SliderContainer>
                        <Slider {...settings}>
                            {section?.metadata?.staff?.filter((item: StaffData) => item.staffAttrs.id == '').map((stylist: StaffData, index: number) => (
                                <CarouselContainer key={index}>
                                    <Skeleton variant="rect" animation={false} width={"100%"} height={"100%"} style={{ background: "rgba(217, 217, 217, 1)" }} />
                                </CarouselContainer>
                            ))}
                        </Slider>
                    </SliderContainer>
                    <Box style={webStyle.btnSkeleton}>
                        {this.skeletonButton()}
                    </Box>
                </Box>
            </>
        )
    }

    renderTestimonialSkeleton = () => {
        return (
            <>
                <MainBoxTestimonial style={{ background: "#969696" }}>
                    <Skeleton animation="wave" height={20} width={100} style={webStyle.headerScal} />
                    <Box className="cardBox">
                        <Box className="card">
                            <Box className="cardHead">
                                <Box className="imageContainer" >
                                    <Skeleton animation={false} height={10} width={100} style={webStyle.textScal} />
                                </Box>
                                <img src={quote} className="quoteIcon" />
                            </Box>
                            <Box className="cardContent">
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                            </Box>
                        </Box>
                        <Box className="card centerCard">
                            <Box className="cardHead">
                                <Box className="imageContainer" >
                                    <Skeleton animation={false} height={10} width={100} style={webStyle.textScal} />
                                </Box>
                                <img src={quote} className="quoteIcon" />
                            </Box>
                            <Box className="cardContent">
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                            </Box>
                        </Box>
                        <Box className="card">
                            <Box className="cardHead">
                                <Box className="imageContainer" >
                                    <Skeleton animation={false} height={10} width={100} style={webStyle.textScal} />
                                </Box>
                                <img src={quote} className="quoteIcon" />
                            </Box>
                            <Box className="cardContent">
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                                <Skeleton animation={false} height={10} width={"100%"} style={webStyle.descriptionScall} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="btnBox">
                        <IconButton className="iconBtn">
                            <img src={leftIcon} className="slideBtn" />
                        </IconButton>
                        <IconButton className="iconBtn">
                            <img src={rightIcon} className="slideBtn" />
                        </IconButton>
                    </Box>
                </MainBoxTestimonial>
            </>
        )
    }

    renderLocationSkeleton = () => {
        return (
            <LocationWrapper>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Skeleton animation="wave" height={11} width={100} style={webStyle.textSkeleton} />

                </Box>
                <LocationScalBox>
                    <Skeleton variant="rect" animation={false} width={"100%"} height={"100%"} style={{ background: "rgba(217, 217, 217, 1)" }} />
                </LocationScalBox>
                <LocationCard style={{ background: "#E8E8E8" }}>
                    <Box>
                        <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#0F172A" }} />
                        <Skeleton animation="wave" height={10} width={80} style={{ marginBottom: 6, background: "#767677" }} />
                        <Skeleton animation="wave" height={10} width={70} style={{ marginBottom: 6, background: "#767677" }} />
                        <Skeleton animation="wave" height={10} width={30} style={{ marginBottom: 6, background: "#767677" }} />
                    </Box>
                    <Divider style={webStyle.divider} />
                    <Box>
                        <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#0F172A" }} />
                        <OpeneingBoxScal>
                            <Box>
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                            </Box>
                            <Box>
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                                <Skeleton animation="wave" height={10} width={50} style={{ marginBottom: 6, background: "#767677" }} />
                            </Box>
                            <LocationIconImg
                                src={LocationIcon}
                                style={{
                                    left: "45px",
                                    position: "relative",
                                    bottom: "170px",
                                    width: "48px",
                                    height: "50px"
                                }}
                            />
                        </OpeneingBoxScal>
                    </Box>
                </LocationCard>
            </LocationWrapper>
        )
    }

    renderExtraStaffTemplate = (staffdata: IStaffData[]) => {
      const selectedColor = this.props.statesData.selectedColor;
      const selectedFonts = this.props.statesData.selectedFonts;
      return <>
        <Grid item xs={12} style={{ margin: '20px 0px' }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ gap: '10px' }}>
            {staffdata?.filter((item: IStaffData) => item.staffAttrs.id)?.slice(0, 3).map((serviceItem: IStaffData, index: number) => {
              const service = serviceItem?.staffAttrs?.attributes;
              return (
                <Grid item xs={12} sm={4}
                  className={"outerBox"}
                  key={index}>
                  <Box className={"extraSectionCardLayout"}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography
                            variant="body1"
                            className="extraServiceTitle"
                            style={{
                              color: selectedColor.secondary,
                              fontFamily: selectedFonts.body,
                            }}
                          >{service?.full_name}</Typography>
                        </Grid>
                        <Grid item>
                          <img src={Array.isArray(service?.image) ? service?.image[0].url
                            : service?.image}
                            className={"extraSectionImage"} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ height: '34px', margin: '10px 0px' }}>
                      <Typography variant={'body1'}
                        className={"notMoreDescription"}
                        style={{
                          color: selectedColor.secondary,
                          fontFamily: selectedFonts.body,
                        }}
                      >{this.getShortDescription(service.description)}
                        {service.description.length > 120 && (
                          <Typography component={'div'}
                            display="inline" className={"showMoreText"}
                            style={{ color: selectedColor.primary, fontFamily: selectedFonts.body }}
                          >{configJSON.showMoreText}</Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ minHeight: '35px' }}>
                        <Grid item>
                          <Box className={"extraBookNowButton"}
                            style={{ background: selectedColor.primary, fontFamily: selectedFonts.body }}
                          >
                            {configJSON.textBookNow}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </>
    }

    renderFocussedTemplate = (staffdata: IStaffData[]) => {
      return <>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {staffdata?.filter((item) => item.staffAttrs.id)?.slice(0, 1).map((staffItem: StaffData, index: number) => {
                const staff = staffItem?.staffAttrs?.attributes;
                return (
                  <Card elevation={0} className="cardlayoutFocussed">
                    <CardContent className="cardContent">
                      <Box position="relative">
                        <img
                          src={staff.image?.trim() ? staff.image : StaffDummyImage}
                          alt="Staff Image"
                          width="100%"
                          height="100%"
                          className="focussedFirstImage"
                        />
                        <Box className="overlayStyle"></Box>
                        <Box className="focussedBigStarBox">
                          <StarIcon className="focussedBigStar" />
                        </Box>
                      </Box>
                      <Box className="cardFooterConatiner">
                        <Box className="priceContainer">
                          <Typography>{staff?.full_name}</Typography>
                          <Box className="priceBox">
                            <Typography variant="body2" className="serviceBrief">
                              <Typography className="designationBig">
                                {staff?.designation}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="bugCardPlusButton">
                          <PlusIcon className="bigPlusIcon" />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {staffdata
                  ?.filter((item) => item.staffAttrs.id)
                  ?.slice(1, 5)
                  .map((staffItem: StaffData, index: number) => {
                    const staff = staffItem?.staffAttrs?.attributes;
                    return (
                      <Grid item xs={6} key={index}>

                        <Card elevation={0} className="smallCardsFocussed">
                          <CardContent className="smallCardContent">
                            <Box position="relative">
                              <img
                                src={staff.image?.trim() ? staff.image : StaffDummyImage}
                                alt="Staff Image"
                                width="100%"
                                height="100%"
                                className="smallCard"
                              />
                              <Box className="overlayStyle"></Box>
                              <Box className="focussedSmallStarBox">
                                <StarIcon className="focussedSmallStar" />
                              </Box>
                            </Box>
                            <Box className="cardFooterConatiner">
                              <Box className="priceContainer">
                                <Typography>{staff?.full_name}</Typography>
                                <Box className="priceBox">
                                  <Typography variant="body2" className="serviceBrief">
                                    <Typography className="designations">
                                      {staff?.designation}
                                    </Typography>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="smallCardPlusButton">
                                <PlusIcon className="smallllPlusIcon" />
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        if (this.props.statesData.expandedSection != this.props.selectedSection) {
            setTimeout(() => {
                this.scrollToTarget();
            }, 0);
        }

        const { locationDetails } = this.state;
        const selectedColor = this.props.statesData.selectedColor;
        const selectedFonts = this.props.statesData.selectedFonts;
        const themeName = this.props?.statesData?.updateData?.header?.templateDetails?.name;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {
                    this.props.statesData.isLoading ?
                        <Backdrop style={{ zIndex: 9999 }} open={this.props.statesData.isLoading} >
                            <div
                                style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress
                                    size="5rem"
                                    color="primary"
                                    data-testid="page-loader"
                                />
                            </div>
                        </Backdrop> :
                        <ProductPreviewBox id="myScrollableDiv">
                            <DesktopPreview>
                                {this.props.statesData.isHeaderEnable ?
                                    themeName === "Elevate" || themeName === "Nexus" ?
                                        this.renderCustomHeader(themeName, selectedColor, selectedFonts)
                                        :
                                        this.renderCustomTheamHeader(themeName, selectedColor, selectedFonts)
                                    :
                                    this.renderCustomHeader("", selectedColor, selectedFonts)
                                }
                                {this.props.statesData.SectionData?.map((section: any, index: any) => {
                                    const { metadata, bannerPosition, bannerBackgroundColour, desktopBannerMetadata } = section;
                                    if (section?.type?.toLowerCase() === "banner" && !section?.is_deleted) {
                                        return section?.desktopBannerVariants?.desktop_banner_variant_1920 ? (
                                            <>
                                                <BannerContainer style={{ flexDirection: bannerPosition === "left" ? "row-reverse" : "row", backgroundColor: bannerBackgroundColour }} key={section?.id} id={`section${index}Unique`}>
                                                    <BannerText
                                                        style={{
                                                            fontFamily: desktopBannerMetadata?.font_family,
                                                            color: desktopBannerMetadata?.font_colour,
                                                            fontSize: `${(desktopBannerMetadata?.font_size) / 1.92}px`
                                                        }}
                                                    >{section?.desktopBannerMetadata?.header_text}</BannerText>
                                                    <BannerImageContainer>
                                                        {(src: string) =>
                                                            <img
                                                                src={section?.desktopBannerVariants?.desktop_banner_variant_1920}
                                                                style={webStyle.banner}
                                                                alt="image loading"
                                                            />
                                                        }
                                                    </BannerImageContainer>
                                                </BannerContainer>
                                            </>
                                        ) : (
                                            this.renderBannerSkeleton(index)
                                            // <AddBannerSection>
                                            //     <InnerAddBannerBox>
                                            //         <AddIconBox>
                                            //             <AddBannerPlusIcon src={AddIcon} />
                                            //         </AddIconBox>
                                            //         <AddBannerText>Add Banner</AddBannerText>
                                            //         <AddBannerSizeText>Max 20MB</AddBannerSizeText>
                                            //     </InnerAddBannerBox>
                                            // </AddBannerSection>
                                        );
                                    } else if (section?.type?.toLowerCase() === "staff_display" && !section?.is_deleted) {
                                        let isImageAvailable = false;
                                        const staffdata = section?.metadata?.staff;
                                        isImageAvailable = staffdata.reduce((initialCondition: boolean, staffdata: { staffAttrs: { attributes: { image: string; }; }; }) => { return staffdata.staffAttrs?.attributes?.image !== "" || initialCondition }, true);
                                        return (
                                            <Box style={webStyle.bodyContainer} key={section?.id} id={`section${index}Unique`}>
                                                {isImageAvailable ?
                                                    <>
                                                        {section.templateDetails?.name === 'Extra' ?
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Grid container direction="row" alignItems="center" style={{ padding: '0px 30px' }}>
                                                                        <Grid item xs={12} sm={3} />
                                                                        <Grid item xs={12} sm={6}>
                                                                        <Box style={{ ...webStyle.headerText, fontFamily: "var(--website-builder-header-font)" }}>{section?.sectionName}</Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={3}>
                                                                            <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                                                                                <Grid item>
                                                                                    <Box style={webStyle.arrowBoxStaff}>
                                                                                        <ArrowBackIcon style={webStyle.arrownIcon} />
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Box style={webStyle.arrowBoxStaff}>
                                                                                        <ArrowForwardIcon style={webStyle.arrownIcon} />
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                            :
                                                            <Box style={{ ...webStyle.headerText, fontFamily: "var(--website-builder-header-font)" }}>{section?.sectionName}</Box>
                                                        }
                                                    </>
                                                    : null}
                                                    {isImageAvailable ?
                                                        <>
                                                        <MainStaffBox>
                                                        <Box className={section.templateDetails?.name === 'Extra' || section.templateDetails?.name === 'Focussed'?  "bodySection": "minimalBodySection"}>
                                                                {section.templateDetails?.name === 'Extra' ?
                                                                    this.renderExtraStaffTemplate(staffdata) :
                                                                    section.templateDetails?.name === 'Focussed' ?
                                                                        this.renderFocussedTemplate(staffdata) :
                                                                        <>
                                                                            <SliderContainer>
                                                                                <Slider {...settings}>
                                                                                    {section?.metadata?.staff?.filter((item: StaffData) => item.staffAttrs.id).map((stylist: StaffData, index: number) => (
                                                                                        <CarouselContainer key={index}>
                                                                                            {stylist?.staffAttrs?.attributes?.image ?
                                                                                                <img src={stylist?.staffAttrs?.attributes?.image} style={webStyle.textContainer} />
                                                                                                :
                                                                                                <img src={StaffDummyImage} style={webStyle.textContainer} />
                                                                                            }
                                                                                            <Box style={webStyle.overlay}>
                                                                                                <Typography style={{ ...webStyle.staffName, fontFamily: "var(--website-builder-body-font)" }}>{stylist?.staffAttrs?.attributes?.full_name}</Typography>
                                                                                                <Typography style={{ ...webStyle.designation, fontFamily: "var(--website-builder-body-font)" }}>{stylist?.staffAttrs?.attributes?.designation}</Typography>
                                                                                            </Box>
                                                                                        </CarouselContainer>
                                                                                    ))}
                                                                                </Slider>
                                                                            </SliderContainer>
                                                                        </>
                                                                        }
                                                                    </Box>
                                                            <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                                <ViewAllBtn style={{ backgroundColor: selectedColor.primarySecond, color: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                                                    {configJSON.viewAllText}
                                                                </ViewAllBtn>
                                                            </Box>
                                                        </MainStaffBox>
                                                    </>
                                                    :
                                                    this.renderStaffSkeleton(section, index)
                                                }
                                            </Box>
                                        );
                                    } else if (section?.type?.toLowerCase() === "service_display" && !section?.is_deleted) {
                                        const selectedServices = section?.metadata?.services?.filter((service: { selected: string }) => service.selected !== "Select");
                                        let isImageAvailable = false;
                                        const servicedata = section?.metadata?.services;
                                        isImageAvailable = servicedata.reduce((initialCondition: boolean, servicedata: { serviceAttrs: { attributes: { images: string; }; }; }) => { return servicedata.serviceAttrs?.attributes?.images !== "" || initialCondition }, true);
                                        return (
                                            <Box key={section?.id} id={`section${index}Unique`} style={{ padding: "15px" }}>
                                                <Box style={{ position: 'relative' }}>
                                                    {isImageAvailable ?
                                                        <>
                                                            {section.templateDetails?.name === 'Extra' ?
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Grid container direction="row" alignItems="center" style={{ padding: '0px 30px' }}>
                                                                            <Grid item xs={12} sm={3} />
                                                                            <Grid item xs={12} sm={6}>
                                                                                <SectionHeading style={{ color: selectedColor.secondary, fontFamily: selectedFonts.header, marginTop: '0px' }}>{section?.sectionName}</SectionHeading>

                                                                            </Grid>
                                                                            <Grid item xs={12} sm={3}>
                                                                                <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                                                                                    <Grid item>
                                                                                        <Box style={webStyle.arrowBox}>
                                                                                            <ArrowBackIcon style={webStyle.arrownIcon} />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Box style={webStyle.arrowBox}>
                                                                                            <ArrowForwardIcon style={webStyle.arrownIcon} />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    <SectionHeading style={{ color: selectedColor.secondary, fontFamily: selectedFonts.header }}>{section?.sectionName}</SectionHeading>
                                                                </>
                                                            }
                                                        </>


                                                        : null}
                                                </Box>
                                                {isImageAvailable ?
                                                    <>
                                                        <MainServiceBox>
                                                            <Box className={section.templateDetails?.name === 'Extra' ? "themeBodySection" : "bodySection"}>
                                                                {section.templateDetails?.name === 'Extra' ?
                                                                    <>
                                                                        <Grid item xs={12} style={{ margin: '20px 0px' }}>
                                                                            <Grid container direction="row" alignItems="center" justifyContent="center" style={{ gap: '10px' }}>
                                                                                {selectedServices?.filter((item: Section) => item.serviceAttrs.id)?.slice(0, 3).map((serviceItem: Section, index: number) => {
                                                                                    const service = serviceItem?.serviceAttrs?.attributes;
                                                                                    return (
                                                                                        <Grid item xs={12} sm={4}
                                                                                            className={"outerBox"}
                                                                                            key={index}>
                                                                                            <Box
                                                                                                className={"extraSectionCardLayout"}

                                                                                            >
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                                                                        <Grid item>
                                                                                                            <Typography
                                                                                                                variant="body1"
                                                                                                                className="extraServiceTitle"
                                                                                                                style={{
                                                                                                                    color: selectedColor.secondary,
                                                                                                                    fontFamily: selectedFonts.body,
                                                                                                                }}
                                                                                                            >{service?.title}</Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <img src={Array.isArray(service?.images) ? service?.images[0].url
                                                                                                                : service?.images}
                                                                                                                className={"extraSectionImage"} />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid item xs={12}>

                                                                                                    <Box className={"extraBoxTime"}>
                                                                                                        <Typography className={"extraServiceMinutes"}
                                                                                                        >{service?.duration}&nbsp;Mins</Typography>
                                                                                                    </Box>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} style={{ height: '34px', margin: '10px 0px' }}>
                                                                                                    <Typography variant={'body1'}
                                                                                                        className={"notMoreDescription"}

                                                                                                        style={{
                                                                                                            color: selectedColor.secondary,
                                                                                                            fontFamily: selectedFonts.body,
                                                                                                        }}
                                                                                                    >{this.getShortDescription(service.description)}
                                                                                                        {service.description.length > 120 && (
                                                                                                            <Typography component={'div'}
                                                                                                                display="inline" className={"showMoreText"}
                                                                                                                style={{ color: selectedColor.primary, fontFamily: selectedFonts.body }}
                                                                                                            >{configJSON.showMoreText}</Typography>
                                                                                                        )}
                                                                                                    </Typography>

                                                                                                </Grid>
                                                                                                <Grid item xs={12} style={{ margin: '10px 0px', border: "1px solid #22222226" }}>
                                                                                                    <Divider />

                                                                                                </Grid>
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ minHeight: '35px' }}>
                                                                                                        <Grid item>
                                                                                                            <Box className={"extraPriceBox"} style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>
                                                                                                                <Typography variant="body2"
                                                                                                                    className={"serviceBrief"}
                                                                                                                    style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body, display: 'flex', flexDirection: 'column' }}
                                                                                                                >
                                                                                                                    <Currency serviceType={"Extra"}
                                                                                                                        fontColor={selectedColor.secondary}
                                                                                                                        fontFamily={selectedFonts.body}
                                                                                                                        text={service?.discount ? service?.discount?.toFixed(2) : ""}
                                                                                                                        disountPrice={`${service?.actual_price ? service?.actual_price.toFixed(2) : ""}`}
                                                                                                                        time={``} hasDiscount={service?.discount_option} />
                                                                                                                </Typography>

                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <Box className={"extraBookNowButton"}
                                                                                                                style={{ background: selectedColor.primary, fontFamily: selectedFonts.body }}
                                                                                                            >
                                                                                                                {configJSON.textBookNow}
                                                                                                            </Box>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>


                                                                                            </Box>
                                                                                        </Grid>
                                                                                    )
                                                                                })}

                                                                            </Grid>

                                                                        </Grid>
                                                                    </> :
                                                                    section.templateDetails?.name === 'Focussed' ?
                                                                        <>
                                                                            <Grid item xs={12} style={{ overflow: 'hidden' }}>
                                                                                <Grid container spacing={2} style={{ padding: "15px" }}>
                                                                                    {/* First Grid: Display the first image */}
                                                                                    <Grid item xs={12} md={6}>
                                                                                        {selectedServices
                                                                                            ?.filter((item: Section) => item.serviceAttrs.id)
                                                                                            ?.slice(0, 1) // Take the first image
                                                                                            .map((service: Section, index: number) => (
                                                                                                <Card elevation={0} key={index} className="serviceCard">
                                                                                                    <CardContent className="focussedCardContent">
                                                                                                        <Box className="focusedImageBox">
                                                                                                            <img
                                                                                                                src={
                                                                                                                    Array.isArray(service?.serviceAttrs?.attributes?.images)
                                                                                                                        ? service?.serviceAttrs?.attributes?.images[0].url
                                                                                                                        : service?.serviceAttrs?.attributes?.images
                                                                                                                }
                                                                                                                alt={service?.serviceAttrs?.attributes?.title}
                                                                                                                width="100%"
                                                                                                                height="100%"
                                                                                                                style={{ borderRadius: "12px", objectFit: "cover" }}
                                                                                                            />
                                                                                                            <Box className="focussedBigTimeContainer">
                                                                                                                <Box className="duration">
                                                                                                                    {service?.serviceAttrs?.attributes?.duration}
                                                                                                                </Box>
                                                                                                                <Box className="minutes">Mins</Box>
                                                                                                            </Box>
                                                                                                            <Box className="focussedBigStarBox">
                                                                                                                <StarIcon className={"focussedBigStar"} />

                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                        <Box className="focussedcardfooter">
                                                                                                            <Box>
                                                                                                                <Typography
                                                                                                                    className="extraServiceTitle"
                                                                                                                    style={{
                                                                                                                        color: selectedColor.secondary,
                                                                                                                        fontFamily: selectedFonts.body,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {service?.serviceAttrs?.attributes?.title?.substring(0, 20)}
                                                                                                                </Typography>
                                                                                                                <Box
                                                                                                                    className="focussedPriceBox"
                                                                                                                    style={{
                                                                                                                        color: selectedColor.secondary,
                                                                                                                        fontFamily: selectedFonts.body,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography
                                                                                                                        variant="body2"
                                                                                                                        className="serviceBrief"
                                                                                                                        style={{
                                                                                                                            color: selectedColor.secondary,
                                                                                                                            fontFamily: selectedFonts.body,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Currency
                                                                                                                            text={`${service?.serviceAttrs?.attributes?.discount
                                                                                                                                ? service?.serviceAttrs?.attributes?.discount?.toFixed(
                                                                                                                                    2
                                                                                                                                )
                                                                                                                                : ""
                                                                                                                                } `}
                                                                                                                            disountPrice={`${service?.serviceAttrs?.attributes?.actual_price
                                                                                                                                ? service?.serviceAttrs?.attributes?.actual_price?.toFixed(
                                                                                                                                    2
                                                                                                                                )
                                                                                                                                : ""
                                                                                                                                }`}
                                                                                                                            time={``}
                                                                                                                            hasDiscount={
                                                                                                                                service?.serviceAttrs?.attributes?.discount_option
                                                                                                                            }
                                                                                                                            fontColor={selectedColor.secondary}
                                                                                                                            fontFamily={selectedFonts.body}
                                                                                                                        />
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                            <Box
                                                                                                                className="focussedplusButton"
                                                                                                                style={{
                                                                                                                    backgroundColor: selectedColor.primary,
                                                                                                                    fontFamily: selectedFonts.body,
                                                                                                                }}
                                                                                                            >
                                                                                                                <AddOutlinedIcon style={{ width: '20px', height: '20px' }} />

                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </CardContent>
                                                                                                </Card>
                                                                                            ))}
                                                                                    </Grid>

                                                                                    {/* Second Grid: Display the remaining four images */}
                                                                                    <Grid item xs={12} md={6}>
                                                                                        <Grid container justifyContent="center" alignItems="center">
                                                                                            {selectedServices
                                                                                                ?.filter((item: Section) => item.serviceAttrs.id)
                                                                                                ?.slice(1, 5) // Take the next 4 images
                                                                                                .map((service: Section, index: number) => (
                                                                                                    <Grid item xs={6} key={index}>
                                                                                                        <Card elevation={0} className="serviceCard">
                                                                                                            <CardContent className="focussedCardContent">
                                                                                                                <Box className="focusedSmallImageBox">
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            Array.isArray(service?.serviceAttrs?.attributes?.images)
                                                                                                                                ? service?.serviceAttrs?.attributes?.images[0].url
                                                                                                                                : service?.serviceAttrs?.attributes?.images
                                                                                                                        }
                                                                                                                        alt={service?.serviceAttrs?.attributes?.title}
                                                                                                                        width="100%"
                                                                                                                        height="100%"
                                                                                                                        style={{ borderRadius: "12px", objectFit: "cover" }}
                                                                                                                    />
                                                                                                                    <Box className="focussedBigTimeContainer">
                                                                                                                        <Box className="duration">
                                                                                                                            {service?.serviceAttrs?.attributes?.duration}
                                                                                                                        </Box>
                                                                                                                        <Box className="minutes">Mins</Box>
                                                                                                                    </Box>
                                                                                                                    <Box className="focussedSmallStarBox">
                                                                                                                        <StarIcon className="focussedSmallStar"
                                                                                                                         style={{
                                                                                                                            color: selectedColor.primary,
                                                                                                                        }}
                                                                                                                        />
                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                                <Box className="focussedcardfooter">
                                                                                                                    <Box>
                                                                                                                        <Typography
                                                                                                                            className="secondTitle"
                                                                                                                            style={{
                                                                                                                                color: selectedColor.secondary,
                                                                                                                                fontFamily: selectedFonts.body,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {service?.serviceAttrs?.attributes?.title?.substring(
                                                                                                                                0,
                                                                                                                                20
                                                                                                                            )}
                                                                                                                        </Typography>
                                                                                                                        <Box
                                                                                                                            className="secondFocussedPriceBox"
                                                                                                                            style={{
                                                                                                                                color: selectedColor.secondary,
                                                                                                                                fontFamily: selectedFonts.body,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <Typography
                                                                                                                                variant="body2"
                                                                                                                                className="serviceBrief"
                                                                                                                                style={{
                                                                                                                                    color: selectedColor.secondary,
                                                                                                                                    fontFamily: selectedFonts.body,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Currency
                                                                                                                                    text={`${service?.serviceAttrs?.attributes?.discount
                                                                                                                                        ? service?.serviceAttrs?.attributes?.discount?.toFixed(
                                                                                                                                            2
                                                                                                                                        )
                                                                                                                                        : ""
                                                                                                                                        } `}
                                                                                                                                    disountPrice={`${service?.serviceAttrs?.attributes?.actual_price
                                                                                                                                        ? service?.serviceAttrs?.attributes?.actual_price?.toFixed(
                                                                                                                                            2
                                                                                                                                        )
                                                                                                                                        : ""
                                                                                                                                        }`}
                                                                                                                                    time={``}
                                                                                                                                    hasDiscount={
                                                                                                                                        service?.serviceAttrs?.attributes?.discount_option
                                                                                                                                    }
                                                                                                                                    fontColor={selectedColor.secondary}
                                                                                                                                    fontFamily={selectedFonts.body}
                                                                                                                                />
                                                                                                                            </Typography>
                                                                                                                        </Box>
                                                                                                                    </Box>
                                                                                                                    <Box
                                                                                                                        className="focussedBookNowButton"
                                                                                                                        style={{
                                                                                                                            backgroundColor: selectedColor.primary,
                                                                                                                            fontFamily: selectedFonts.body,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <AddOutlinedIcon style={{ width: '14px', height: '14px' }} />

                                                                                                                    </Box>
                                                                                                                </Box>
                                                                                                            </CardContent>
                                                                                                        </Card>
                                                                                                    </Grid>
                                                                                                ))}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>


                                                                            </Grid>
                                                                        </> :
                                                                        <>
                                                                            <Slider {...settingsTopServices} className={'sliderContainer'}>
                                                                                {selectedServices?.filter((item: Section) => item.serviceAttrs.id).map((service: Section, curElem: number) => (
                                                                                    <Card elevation={0} key={curElem} className="serviceCard">
                                                                                        <CardContent className={"cardContent"}>
                                                                                            <Box className={"imageBox"}>

                                                                                                <img src={Array.isArray(service?.serviceAttrs?.attributes?.images) ? service?.serviceAttrs?.attributes?.images[0].url :
                                                                                                    service?.serviceAttrs?.attributes?.images} alt={service?.serviceAttrs?.attributes?.title}
                                                                                                    width="100%" height="100%" style={{ borderRadius: "12px", objectFit: "cover" }} />
                                                                                                <Box className={"timeContainer"}>
                                                                                                    <Box className={"duration"}>{service?.serviceAttrs?.attributes?.duration}</Box>
                                                                                                    <Box className={"minutes"}>Mins</Box>
                                                                                                </Box>
                                                                                                <Box>

                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box className={"cardFooterConatiner"}>
                                                                                                <Box>
                                                                                                    <Typography className="cardDesc" style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>{service?.serviceAttrs?.attributes?.title?.substring(0, 20)}</Typography>
                                                                                                    <Box className={"priceBox"} style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>
                                                                                                        <Typography variant="body2" className={"serviceBrief"} style={{ color: selectedColor.secondary, fontFamily: selectedFonts.body }}>
                                                                                                            <Currency
                                                                                                                text={`${service?.serviceAttrs?.attributes?.discount ? service?.serviceAttrs?.attributes?.discount?.toFixed(2) : ""} `}
                                                                                                                disountPrice={`${service?.serviceAttrs?.attributes?.actual_price ? service?.serviceAttrs?.attributes?.actual_price?.toFixed(2) : ""}`}
                                                                                                                time={``}
                                                                                                                hasDiscount={service?.serviceAttrs?.attributes?.discount_option}
                                                                                                                fontColor={selectedColor.secondary}
                                                                                                                fontFamily={selectedFonts.body}
                                                                                                            />
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                                <Box className={"bookNowButton"} style={{ backgroundColor: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                                                                                    {configJSON.textBookNow}
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                ))}
                                                                            </Slider>
                                                                        </>
                                                                }
                                                                {/* {section.template_details} */}





                                                            </Box>
                                                            <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                                <ViewAllBtn style={{ marginTop: 0, backgroundColor: selectedColor.primarySecond, color: selectedColor.primary, fontFamily: selectedFonts.body }}>
                                                                    {configJSON.viewAllText}
                                                                </ViewAllBtn>
                                                            </Box>
                                                        </MainServiceBox>
                                                    </>
                                                    :
                                                    this.renderServiceSkeleton(selectedServices, index)
                                                }
                                            </Box>
                                        );
                                    } else if (section?.type?.toLowerCase() === "testimonial" && !section?.is_deleted && metadata?.isTestimonial) {
                                        const testimonialData = section?.customer_feedback
                                        return (
                                            testimonialData?.length ? (
                                                <MainBoxTestimonial key={section?.id} id={`section${index}Unique`}>
                                                    <Typography align="center" variant="h6" className="sectionName" style={{ color: selectedColor.secondary, fontFamily: selectedFonts.header }}>
                                                        {section?.sectionName}
                                                    </Typography>
                                                    <Box className="cardBox">
                                                        {testimonialData.map((item: StaffData, index: number) => (
                                                            <Box className={index === 1 ? "card centerCard" : "card"}>
                                                                <Box className="cardHead">
                                                                    <Box className="imageContainer" >
                                                                        <Typography className="testimonialName1" style={{ fontFamily: selectedFonts.body, color: selectedColor.secondary }}>{item?.customer_name}</Typography>
                                                                    </Box>
                                                                    <img src={quote} className="quoteIcon" />
                                                                </Box>
                                                                <Box className="cardContent">
                                                                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item?.description }} className="cardContentBody" style={{ fontFamily: selectedFonts.body, color: selectedColor.secondarySecond }}>
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Box className="btnBox">
                                                        <IconButton className="iconBtn">
                                                            <img src={leftIcon} className="slideBtn" />
                                                        </IconButton>
                                                        <IconButton className="iconBtn">
                                                            <img src={rightIcon} className="slideBtn" />
                                                        </IconButton>
                                                    </Box>
                                                </MainBoxTestimonial>
                                            ) :
                                                this.renderTestimonialSkeleton()
                                        );
                                    } else if (section?.type?.toLowerCase() === "location" && !section?.is_deleted && metadata?.isLocation) {
                                        return (
                                            <LocationContainer key={section?.id} id={`section${index}Unique`}>
                                                <LocationTitleBox>
                                                    {locationDetails ? <LocationTitle style={{ color: selectedColor.secondary, fontFamily: selectedFonts.header }}>{section?.sectionName}</LocationTitle> : null}
                                                </LocationTitleBox>
                                                {locationDetails ?
                                                    <LocationWrapper>
                                                        <LocationMapBox>
                                                            <img
                                                                width={"100%"}
                                                                src={(locationDetails?.location?.image as ServiceImage)?.small_url ? (locationDetails?.location.image as ServiceImage)?.small_url : (locationDetails?.location.image as ServiceImage)?.url}
                                                                alt="image loading"
                                                                style={webStyle.img}
                                                            />
                                                        </LocationMapBox>
                                                        <LocationCard>
                                                            <Box>
                                                                <AddressTitle style={{ fontFamily: selectedFonts.body }}>{configJSON.address}</AddressTitle>
                                                                <AddressBody style={{ fontFamily: selectedFonts.body }}>{`${locationDetails?.addressLine1},${locationDetails?.addressLine2},${locationDetails?.city},${locationDetails?.country}`}</AddressBody>
                                                            </Box>
                                                            <Divider style={webStyle.divider} />
                                                            <Box>
                                                                <AddressTitle style={{ fontFamily: selectedFonts.body }}>{configJSON.openingTime}</AddressTitle>
                                                                {locationDetails?.shopAvailability.map(
                                                                    ({ weekDay, openingTime, closingTime }, index) => (
                                                                        <OpeneingBox key={index}>
                                                                            <AddressBody style={{ fontFamily: selectedFonts.body }}>
                                                                                {weekDay}
                                                                            </AddressBody>
                                                                            <AddressBody style={{ fontFamily: selectedFonts.body }}>
                                                                                {openingTime} - {closingTime}
                                                                            </AddressBody>
                                                                        </OpeneingBox>
                                                                    )
                                                                )}
                                                            </Box>
                                                        </LocationCard>

                                                    </LocationWrapper>
                                                    :
                                                    this.renderLocationSkeleton()
                                                }
                                            </LocationContainer>

                                        );
                                    }
                                })}
                                <Box id="footerUnique">
                                    <FooterSectionContainer>
                                        <FooterSectionBox>
                                            <Box>
                                                {this.state.staticPagesData.length > 0 ? <FooterHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.helpCenter}</FooterHeadingText> : null}
                                                {this.state.staticPagesData.map(item => (
                                                    <FooterSubHeadingText style={{ fontFamily: selectedFonts.body }}>{item?.attributes?.title}</FooterSubHeadingText>
                                                ))}
                                            </Box>
                                            {this.props.statesData.updateData.footer?.is_contact &&
                                                <>
                                                    <Box>
                                                        <Box>
                                                            <FooterHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.contactUs}</FooterHeadingText>
                                                            <FooterSocialLinkBox>
                                                                <FooterSubHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.sendMessage}</FooterSubHeadingText>
                                                            </FooterSocialLinkBox>
                                                            <FooterSocialLinkBox>
                                                                <FooterSubHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.contact}</FooterSubHeadingText>
                                                            </FooterSocialLinkBox>
                                                        </Box>
                                                        {this.props.statesData.storeEmail &&
                                                            <Box>
                                                                <FooterHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.email}</FooterHeadingText>
                                                                <FooterSocialLinkBox>
                                                                    <FooterSubHeadingText style={{ fontFamily: selectedFonts.body }}>{this.props.statesData.storeEmail}</FooterSubHeadingText>
                                                                </FooterSocialLinkBox>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </>
                                            }
                                            <Box>
                                                {this.props.statesData.updateData?.footer?.is_app_link && (this.props.statesData.updateData?.footer?.playstore_url.toString()?.length > 0 || this.props.statesData.updateData?.footer?.appstore_url.toString()?.length > 0) &&
                                                    <FooterHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.download}</FooterHeadingText>
                                                }
                                                <MainBox>
                                                    {this.props.statesData.updateData?.footer?.playstore_url?.toString()?.length > 0 && this.props.statesData.updateData?.footer?.is_app_link &&
                                                        <FooterStoreIconBox href={this.props.statesData.updateData?.footer?.playstore_url.toString()} target="_blank">
                                                            <FooterStoreIcon src={GooglePlayIcon} />
                                                        </FooterStoreIconBox>
                                                    }
                                                    {this.props.statesData.updateData.footer?.appstore_url?.toString()?.length > 0 && this.props.statesData.updateData?.footer?.is_app_link &&
                                                        <FooterStoreIconBox href={this.props.statesData.updateData?.footer?.appstore_url.toString()} target="_blank">
                                                            <FooterStoreIcon src={AppStore} />
                                                        </FooterStoreIconBox>
                                                    }
                                                </MainBox>
                                            </Box>
                                        </FooterSectionBox>

                                        {this.props.statesData.updateData.footer?.is_social_media && (this.props.statesData.updateData.footer?.facebook_url.toString()?.length > 0 || this.props.statesData.updateData.footer?.instagram_url.toString()?.length > 0 || this.props.statesData.updateData.footer.x_url.toString().length > 0 || this.props.statesData.updateData.footer?.youtube_url.toString()?.length > 0) &&
                                            <Box>
                                                <FooterHeadingText style={{ fontFamily: selectedFonts.body }}>{configJSON.followUs}</FooterHeadingText>
                                                <FooterIconContainer>
                                                    {(this.props.statesData.updateData.footer.facebook_url && this.props.statesData.updateData.footer?.is_social_media) &&
                                                        <FooterIconBox>
                                                            <FooterSocialLinkBox href={this.props.statesData.updateData.footer.facebook_url.toString()} target="_blank">
                                                                <FooterSocialLinkIcon style={{ width: "5.18px", height: "9.88px" }} src={facebookLogo} />
                                                            </FooterSocialLinkBox>
                                                        </FooterIconBox>
                                                    }

                                                    {this.props.statesData.updateData.footer.instagram_url && this.props.statesData.updateData.footer?.is_social_media &&
                                                        <FooterIconBox>
                                                            <FooterSocialLinkBox href={this.props.statesData.updateData.footer.instagram_url.toString()} target="_blank">
                                                                <FooterSocialLinkIcon style={{ width: "9.87px", height: "9.87px" }} src={instagramLogo} />
                                                            </FooterSocialLinkBox>
                                                        </FooterIconBox>
                                                    }

                                                    {(this.props.statesData.updateData.footer.x_url && this.props.statesData.updateData.footer?.is_social_media) &&
                                                        <FooterIconBox>
                                                            <FooterSocialLinkIcon style={{ width: "10.6px", height: "8.18px" }} src={twitterLogo} />
                                                        </FooterIconBox>
                                                    }

                                                    {this.props.statesData.updateData.footer.youtube_url && this.props.statesData.updateData.footer?.is_social_media &&
                                                        <FooterIconBox>
                                                            <FooterSocialLinkBox href={this.props.statesData.updateData.footer.youtube_url.toString()} target="_blank">
                                                                <FooterSocialLinkIcon style={{ width: "9.87px", height: "7.9px" }} src={youtubLogo} />
                                                            </FooterSocialLinkBox>
                                                        </FooterIconBox>
                                                    }
                                                </FooterIconContainer>
                                            </Box>
                                        }
                                    </FooterSectionContainer>

                                    <FooterCopyRightBox>
                                        {this.props.statesData.updateData.footer?.footerCopyRight &&
                                            <FooterCopyRightText component={"span"} style={{ fontFamily: selectedFonts.body }}>
                                                {this.props.statesData.updateData.footer?.footerCopyRight}&nbsp;&nbsp;
                                            </FooterCopyRightText>
                                        }
                                    </FooterCopyRightBox>
                                </Box>

                            </DesktopPreview>
                        </ProductPreviewBox >
                }
            </>
            // Customizable Area End
        );

    }
};


// Customizable Area Start
export default withDialog(withLoader(withHeaderBar(ProductPreviewDesktopScreen)));
export { ProductPreviewDesktopScreen }
// Customizable Area End

// Customizable Area Start
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--website-builder-primary-color').trim();
const webStyle = {
    bodyContainer: {
        background: "linear-gradient(180deg, rgba(34, 34, 34, 1) 59%, rgba(34, 34, 34, 1) 59%, rgba(255,255,255,1) 59%)",
        padding: "20px 0"
    },
    bodyContainerSkeleton: {
        background: "linear-gradient(180deg, rgba(232, 232, 232, 1) 59%, rgba(232, 232, 232, 1) 59%, rgba(255,255,255,1) 59%)",
        padding: "20px 0"
    },
    textSkeleton: {
        background: "#000000",
        marginBottom: "20px",
        borderRadius: '11.02px'
    },
    textSkeleton2: {
        background: "rgba(0, 0, 0, 1)",
        marginBottom: "20px",
        left: "0%",
    },
    headerScal: {
        background: "rgba(0, 0, 0, 1)",
        left: "43%"
    },
    descriptionScall: {
        background: "rgba(118, 118, 119, 1)"
    },
    textScal: {
        background: "rgba(15, 23, 42, 1)"
    },
    btnSkeleton: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "20px"
    },
    textContainer: {
        position: "relative" as "relative",
        height: "100%",
        width: "100%",
        objectFit: "cover" as "cover"
    },
    overlay: {
        position: "absolute" as "absolute",
        bottom: "8px",
        marginLeft: "5px",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)",
    },
    staffName: {
        fontSize: "15px",
        fontWeight: 700,
        letterSpacing: "1px"
    },
    designation: {
        fontSize: "12px",
        fontWeight: 400,
        letterSpacing: "1px"
    },
    headerText: {
        color: "#ffffff",
        fontFamily: "var(--website-builder-header-font)",
        fontSize: "19.75px",
        fontWeight: 700,
        margin: "20px 0",
        textAlign: "center" as "center",
        letterSpacing: "-1%",
        lineHeight: "21.73px"
    },
    sliderContainer: {
        "& .slick-track": {
            display: "flex",
            width: '100vw !important'
        },
        "& .slick-slide": {
            margin: "0 10px",
            height: "auto"
        },
        "& .slick-arrow": {
            "&:before": {
                content: "''"
            }
        }
    },
    viewAllBtn: {
        marginTop: "30px",
        fontSize: "15px",
        lineHeight: "37px",
        fontWeight: 700,
        cursor: "pointer",
        color: "var(--website-builder-primary-color)",
        fontFamily: "var(--website-builder-body-font)",
        textAlign: "center" as "center",
        zIndex: 1,
        textDecoration: "none",
        backgroundColor: "var(--website-builder-primary-light-accent-color)",
        padding: "2px 28px",
        borderRadius: "8px"
    },

    listItem: {
        width: "23%",
        display: "flex",
        flexDirection: "column" as "column",
        textAlign: "center" as "center",
    },

    testimonialStar: {
        fontSize: "24px"
    },

    testimonialRated: {
        color: "#FFC400"
    },

    testimonialUnrated: {
        color: "#C4C4C4"
    },

    img: {
        width: "100%",
        borderRadius: "7.9px"
    },

    divider: {
        border: "2px dashed #D9D9D9A6",
        margin: "10px 0px"
    },

    banner: {
        height: "256px",
        cursor: "pointer",
        width: "254px",
        objectFit: "fill" as "fill",
        borderRadius: "50px 0px 50px 0px"
    },

    skeletonText: {
        position: "absolute" as "absolute",
        top: "11px",
        left: "12px",
        background: "#000000"
    },
    skeletonBtn: {
        background: "#D9D9D9",
        borderRadius: '4.91px'
    },
    skeletonBox: {
        position: "relative" as "relative"
    },
    serviceSkeleton: {
        background: "rgba(217, 217, 217, 1)",
        borderRadius: "10px"
    },
    bannerSkeletonLayout: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',

    },
    bannerSkeleton: {
        height: 11,
        borderRadius: '11.02px',
        background: "#000000"
    },
    staffSkeletonDivider: {
        background: "#0F172A"
    },
    arrowBox: {
        borderRadius: "50%",
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#F3F3F3",
        color: "#000"
    },
    arrowBoxStaff: {
        borderRadius: "50%",
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#616161",
        color: "#fff"
    },
    arrownIcon: {
        width: 12,
        height: 12
    }
};

const CarouselContainer = styled(Box)({
    position: "relative" as "relative",
    overflow: "hidden",
    height: "200px !important",
    width: "160px !important",
    display: "block !important",
    borderRadius: "25px 12px 25px 12px",
    "& img": {
        transition: "transform 1.0s ease !important"
    },
    "&:hover img": {
        transform: "scale(1.5) !important"
    },
    "&:hover div": {
        opacity: 1,
        display: "block !important"
    },
})

const SliderContainer = styled(Box)({
    "& .slick-track": {
        margin: "0 90px",
        display: "flex",
        width: "2660px !important"
    },
    "& .slick-slide": {
        width: "360px !important",
        margin: "0 10px",
        height: "200px"
    },
    "& .slick-arrow": {
        "&:before": {
            content: "''"
        }
    },
    "& .slick-next": {
        right: "10px"
    },
    "& .slick-prev": {
        left: "10px !important"
    }
})

const HeaderContainerBox = styled(Box)({
    display: "flex",
    gap: "25px"
});

const AddBannerPlusIcon = styled("img")({
    height: "15px",
    width: "15px"
});

const SectionHeading = styled(Typography)({
    fontFamily: "var(--website-builder-header-font)",
    color: "var(--website-builder-secondary-color)",
    fontSize: "19.75px",
    fontWeight: 700,
    lineHeight: "21.73px",
    textAlign: "center",
    marginTop: "20px",
});

const HeaderSearchIcon = styled("img")({
    width: "9.88px",
    height: "9.88px",
    objectFit: "contain",
});
const AddBannerText = styled(Typography)({
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "-0.4px",
    color: "#676B7E"
});
const AddBannerSizeText = styled(Typography)({
    fontSize: "10px",
    fontWeight: 400,
    color: "#676B7E"
});
const AddIconBox = styled(Box)({
    display: "flex",
    width: "16px",
    height: "16px",
    padding: "0.5px",
    justifyContent: "center",
    alignItems: "center"
});

const InnerAddBannerBox = styled(Box)({
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
});

const FooterCopyRightBox = styled(Box)({
    width: "100%",
    backgroundColor: "#222222",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    color: "#ffffff"
});
const HeaderIconAndNameHolder = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 1)',
    justifyContent: "space-between",
    width: "50%"
});
const AddLogoText = styled(Typography)({
    fontSize: "7px",
    fontWeight: 500,
    color: "#676B7E"
});


const AddLogoBox = styled(Box)({
    width: "50px",
    height: "34px",
    borderRadius: "6px",
    border: "2px dashed #adafbd",
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    rowGap: "2px"
});
const AddLogoPlusIcon = styled("img")({
    width: "9.2px",
    height: "7.5px",
    padding: "0.31px 0.25px",
    objectFit: "contain",
    color: "#67687E"
});

const AddBannerSection = styled(Box)({
    width: "712px",
    height: "100%",
    padding: "30px",
    boxSizing: "border-box",
    backgroundColor: "#D9D9D9",
    position: "relative",
});

const FooterCopyRightText = styled(Box)({
    color: "#ffffff",
    textAlign: "center",
    fontSize: "5.92px",
    fontWeight: 400,
    lineHeight: "11.85px",
    fontFamily: "var(--website-builder-body-font)",
});

const FooterBuilderText = styled("a")({
    color: "#64748B",
    fontFamily: "var(--body-text-font) !important",
})
const ProductPreviewBox = styled(Box)({
    width: "100%",
    backgroundColor: "#f6f7f9",
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "25px",
    overflow: 'auto',
    height: 'calc(100vh - 242px)'
});

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column"
})

const DesktopPreview = styled(Box)({
    width: "711px",
    backgroundColor: "#ffffff",
    boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    height: "max-content"
});

const FooterSectionBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "50%"
});

const FooterStoreIconBox = styled("a")({
    width: "113px",
    height: "30px",
    borderRadius: "5px",
    textDecoration: "none"
});

const LocationIconBox = styled(Box)({
    width: "113px",
    height: "30px",
    borderRadius: "5px",
    textDecoration: "none"
});
const LocationIconImg = styled("img")({
    width: "68.137px",
    height: "26.169px"
});

const FooterStoreIcon = styled("img")({
    width: "68.137px",
    height: "26.169px"
});

const FooterHeadingText = styled(Typography)({
    color: "#F1F5F9",
    fontSize: "8.89px",
    fontWeight: 600,
    lineHeight: "11.85px",
    margin: "10px 0",
    fontFamily: "var(--website-builder-body-font)",
});

const FooterIconBox = styled(Box)({
    // display: "flex",
    // alignItems: "center",
    // columnGap: "5px",
    border: "1px solid #ffffff",
    borderRadius: "4px",
    width: "20.74px",
    height: "21.73px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const FooterSocialLinkBox = styled("a")({
    textDecoration: "none",
});

const FooterIconContainer = styled(Box)({
    display: "flex",
    gap: "8px"
});

const FooterSocialLinkIcon = styled("img")({
    objectFit: "contain",
});

const FooterSubHeadingText = styled(Typography)({
    fontSize: "6.91px",
    fontWeight: 400,
    lineHeight: "11.85px",
    margin: "2px 0",
    fontFamily: "var(--website-builder-body-font)",
    color: "#E2E8F0"
});

const DesktopPreviewHeader = styled(Box)({
    width: "100%",
    height: "37.51px",
    padding: "10px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    "& input::placeholder": {
        fontSize: "6.91px"
    }
});


const MainStaffBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    overflowX: 'auto',
    "& .minimalBodySection": {
        paddingTop: "10px",
        width: '100%',
    },
    "& .bodySection": {
        width: '100%',
        padding: "10px 30px",
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .themeBodySection": {
        width: '631px',
        display: 'flex',
        justifyContent: 'center',
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .serviceCard": {
        overflow: "visible",
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .cardlayoutFocussed": {
        background: "transparent",
    },
    "& .cardContent": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "0px",
        paddingBottom: "24px",
        position: "relative",
        height: "371px",
        "&> div": {
            background: "transparent"
        },
        "& .MuiTypography-body1": {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            fontFamily: "var(--website-builder-header-font)",
            color: "var(--website-builder-primary-color)",
        }, 
        "& img": {
            borderRadius: "8px",
        }
    },
    "& .focussedCardContent": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "7px",
        "& .MuiTypography-body1": {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            fontFamily: "var(--website-builder-header-font)",
            color: "var(--website-builder-primary-color)",
        }
    },
    "& .cardDesc": {
        fontSize: "9.88px",
        fontWeight: 500,
        lineHeight: "14.81px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .extraServiceTitle": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "21px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .extraSectionImage": {
        width: '33px',
        height: '33px',
        borderRadius: 6
    },
    "& .priceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontWeight: 400,
            lineHeight: "30px",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
        }
    },
    "& .focussedPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "15px !important",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "18px !important"
        }
    },
    "& .secondTitle": {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .secondFocussedPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "8px",
            fontWeight: 500,
            lineHeight: "12px !important",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontSize: "10px",
            fontWeight: 700,
            lineHeight: "17px !important"
        }
    },
    "& .imageBox": {
        width: "100%",
        height: "248px",
        position: 'relative'
    },
    "& .focusedImageBox": {
        width: "313px",
        height: "420px",
        position: 'relative'
    },
    "& .focusedSmallImageBox": {
        width: "147px",
        height: "177px",
        position: 'relative'
    },
   
    "& .focussedBigStarBox": {
        top: "10px",
        right: "10px",
        padding: "8px",
        borderRadius: "3px",
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 28,
        height: 28,
        color: "var(--website-builder-primary-color)",
    },
    "& .focussedBigStar": {
        color: "var(--website-builder-primary-color)",
        width: '16.33px',
        height: '16.33px'
    },
    "& .focussedSmallStarBox": {
        top: "10px",
        right: "10px",
        padding: "8px",
        borderRadius: "3px",
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        color: "var(--website-builder-primary-color)",
    },
    "& .focussedSmallStar": {
        color: "var(--website-builder-primary-color)",
        width: '11.67px',
        height: '11.67px'

    },
    "& .cardFooterConatiner": {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        padding: "16px", 
        position: "absolute",
        bottom: 0,
        color: "white", 
        width: "100%"
    },
    "& .designationBig": {
        color: "white", 
        textTransform: "uppercase"
    },
    "& .designations": {
        color: "white", 
        textTransform: "uppercase", 
        fontSize: "14px"
    },
    "& .focussedcardfooter": {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .bookNowButton": {
        width: "85.42px",
        height: "24.69px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "4px",
        cursor: "pointer",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "9.88px"
    },
    "& .smallCardPlusButton": {
        width: "28px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .focussedBookNowButton": {
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: "9.88px",
        fontWeight: 500,
        lineHeight: "11.85px",
        whiteSpace: 'nowrap',
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .serviceBrief": {
        fontSize: "11px",
        lineHeight: "17.77px",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .outerBox": {
        background: '#fff',
        border: "1px solid #22222226",
        borderRadius: 16,
        boxShadow: "0px 0px 10px 0px #0000000D",
        width: '202px',
        height: '144px',
        maxWidth: '32.2%'
    },
    "& .extraSectionCardLayout": {
        padding: '0.8rem',
    },
    "& .extraBoxTime": {
        background: "#2222220D",
        borderRadius: 6,
        width: 30,
        height: 13,
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
    },
    "& .extraServiceMinutes": {
        fontSize: "6px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "var(--website-builder-body-font)",
        color: '#1CC9D1',
    },
    "& .showMoreText": {
        color: "var(--website-builder-primary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        fontFamily: "var(--website-builder-body-font)",
        textTransform: 'none',
        textDecoration: 'underline',
    },
    "& .notMoreDescription": {
        color: "var(--website-builder-secondary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        fontFamily: "var(--website-builder-body-font)",
    },
    "& .extarCardDescription": {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        // whiteSpace:"nowrap",
        textOverflow: "ellipsis"
    },
    "& .extraBookNowButton": {
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "6px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)",
        width: "59px",
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .extraPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "Rubik",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "8px",
            fontWeight: 500,
            lineHeight: "12px",
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "18px !important"
        }
    },
    "& .smallCardContent": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        // backgroundColor: theme.palette.background.default, 
        borderRadius: "8px", 
        fontSize: "0.875rem", 
        // color: theme.palette.text.primary, 
        lineHeight: 1.5, 
        padding: "0px",
        position: "relative",
        "& img": {
            height: "178px",
            width: "150px",
            objectFit: "cover",
            borderRadius: "8px"
        }
    },
    "& .smallCardsFocussed": {
        background: "transparent"
    }, 
    "& .bugCardPlusButton": {
        width: "28px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)"
    }
}));

const LocationContainer = styled(Box)(({ theme }) => ({
    padding: "20px"
}));

const LocationTitleBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "20px 0 20px 0"
}));

const LocationTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    lineHeight: "21.73px",
    paddingBottom: theme.spacing(1),
    fontSize: "19.75px",
    fontFamily: "var(--website-builder-header-font)",
    color: "var(--website-builder-secondary-color)",
}));

const LocationWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "0px 20px",
    position: "relative"
}));

const LocationCard = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#000000B2",
    padding: "15px 10px",
    width: "209px",
    position: "absolute",
    bottom: "5px",
    marginLeft: "5px",
    borderRadius: "5.9px"
}));

const LocationMapBox = styled(Box)(({ theme }) => ({
    width: "100%",
}));

const LocationScalBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "400px",
    background: "rgba(217, 217, 217, 1)"
}));

const AddressTitle = styled(Typography)(({ theme }) => ({
    fontSize: "8.88px",
    fontWeight: 400,
    lineHeight: "10.86px",
    color: "#FFFFFF",
    fontFamily: "var(--website-builder-body-font)",
}));

const AddressBody = styled(Typography)(({ theme }) => ({
    fontSize: "10.86px",
    lineHeight: "16.26px",
    color: "#FFFFFF"
}));

const OpeneingBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between"
}));

const OpeneingBoxScal = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    width: "280px"
}));

const FooterSectionContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "30px 40px",
    backgroundColor: "#222222",
    boxSizing: "border-box",
    display: 'flex',
    gap: "70px",
    height: "209px",
    justifyContent: "space-between"
}));

const BannerContainer = styled(Box)(({ theme }) => ({
    height: "325px",
    width: "100%",
    backgroundColor: "#E8E8E8",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px"
}));

const BannerText = styled(Typography)(({ theme }) => ({
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "50px",
    width: "50%",
    color: "#fff",
    wordWrap: "break-word"
}));

const BannerImageContainer = styled(Box)(({ theme }) => ({
    width: "256x",
    minWidth: "256px",
    height: "254px"
}));

const BannerImageSkeleton = styled(Box)(({ theme }) => ({
    width: "257x",
    minWidth: "256px",
    height: "255px",
    cursor: "pointer",
    objectFit: "fill" as "fill",
    borderRadius: "23.1px 0px 23.1px 0px",
    background: "#D9D9D9"
}));

const ManageBooking = styled(Box)(({ theme }) => ({
    borderRadius: "3.95px",
    backgroundColor: "var(--website-builder-primary-color)",
    width: "78.01px",
    fontSize: "6.98px",
    color: "#ffffff",
    lineHeight: "11.85px",
    fontWeight: 700,
    fontFamily: "var(--website-builder-body-font)",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "17.77px"
}));


const ViewAllBtn = styled(Box)(({ theme }) => ({
    marginTop: "20px",
    fontSize: "15px",
    lineHeight: "37px",
    fontWeight: 700,
    cursor: "pointer",
    color: "var(--website-builder-primary-color)",
    fontFamily: "var(--website-builder-body-font)",
    textAlign: "center" as "center",
    zIndex: 1,
    textDecoration: "none",
    backgroundColor: "var(--website-builder-primary-light-accent-color)",
    padding: "2px 28px",
    borderRadius: "8px"
}));

const MainBoxTestimonial = styled(Box)({
    background: "#F5F5F5",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "& .sectionName": {
        fontFamily: "var(--website-builder-header-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: "19.75px",
        fontWeight: 700
    },
    "& .MuiTypography-h6": {
        fontSize: "40px",
        fontWeight: 700,
        lineHeight: "40px",
        fontFamily: "Lato",
        color: "#222222"
    },
    "& .cardBox": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "20px"
    },
    "& .card": {
        backgroundColor: "white",
        height: "95px",
        width: "189px",
        borderRadius: "10px 0px 10px 0px",
        padding: "10px 10px 10px 10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        border: "none"
    },
    "& .cardHead": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        height: "30%",
        "& .MuiTypography-body1": {
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "26px",
            fontFamily: "Lato",
            color: "#3C3E49"
        }
    },
    "& .cardContent": {
        textAlign: "justify",
        height: "70%",
        "& .MuiTypography-body2": {
            fontSize: "8.89px",
            fontWeight: 400,
            lineHeight: "13.33px",
            fontFamily: "Lato",
            color: "#676B7E"
        }
    },
    "& .quoteIcon": {
        color: "rgb(0,0,0)",
        width: "21px",
        height: "18px"
    },
    "& .centerCard": {
        height: "127px",
        width: "259px",
        padding: "5px 10px 0px 10px !important",
        "& .MuiTypography-body1": {
            fontSize: "28px !important"
        },
        "& .MuiTypography-body2": {
            fontSize: "18px !important",
            lineHeight: "26px !important"
        }
    },
    "& .btnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px"
    },
    "& .iconBtn": {
        width: "36px",
        height: "36px",
    },
    "& .cardContentBody2": {
        fontSize: "8.89px",
        lineHeight: "13.33px",
        fontWeight: 400,
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-dark-accent-color)"
    },
    "& .cardContentBody": {
        fontSize: "6.91px",
        lineHeight: "10.37px",
        fontWeight: 400,
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-dark-accent-color)"
    },
    "& .imageContainer": {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    "& .testimonialName": {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: "13.82px",
        fontWeight: 700,
        lineHeight: "12.84px"
    },
    "& .testimonialName1": {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: "9.88px",
        fontWeight: 700,
        lineHeight: "12.84px"
    },
    "& .imageCard": {
        width: "27.65px",
        height: "27.65px",
        borderRadius: "50px 0 50px 0",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "fill"
        },
    },
    "& .centerCart": {
        width: "44px",
        height: "44px",
        borderRadius: "50px 0 50px 0",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "fill"
        }
    }
});

const MainServiceBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    overflowX: 'auto',
    "& .minimalBodySection": {
        paddingTop: "10px",
        width: '100%',
        height: '900px'
    },
    "& .bodySection": {
        width: '100%',
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .themeBodySection": {
        width: '631px',
        display: 'flex',
        justifyContent: 'center',
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .serviceCard": {
        overflow: "visible",
        "& .MuiCard-root": {
            borderRadius: "0px",
            overflow: "visible"
        }
    },
    "& .cardContent": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "7px",
        paddingBottom: "24px",
        "& .MuiTypography-body1": {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            fontFamily: "var(--website-builder-header-font)",
            color: "var(--website-builder-primary-color)",
        }
    },
    "& .focussedCardContent": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "7px",
        "& .MuiTypography-body1": {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "30px",
            fontFamily: "var(--website-builder-header-font)",
            color: "var(--website-builder-primary-color)",
        }
    },
    "& .cardDesc": {
        fontSize: "9.88px",
        fontWeight: 500,
        lineHeight: "14.81px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .extraServiceTitle": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "21px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .extraSectionImage": {
        width: '33px',
        height: '33px',
        borderRadius: 6
    },
    "& .priceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontWeight: 400,
            lineHeight: "30px",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
        }
    },
    "& .focussedPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "15px !important",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "18px !important"
        }
    },
    "& .secondTitle": {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
    },
    "& .secondFocussedPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "lato",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "8px",
            fontWeight: 500,
            lineHeight: "12px !important",
            color: "var(--website-builder-secondary-color)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontSize: "10px",
            fontWeight: 700,
            lineHeight: "17px !important"
        }
    },
    "& .imageBox": {
        width: "100%",
        height: "248px",
        position: 'relative'
    },
    "& .focusedImageBox": {
        width: "313px",
        height: "420px",
        position: 'relative'
    },
    "& .focusedSmallImageBox": {
        width: "147px",
        height: "177px",
        position: 'relative'
    },
    "& .timeContainer": {
        top: "10px",
        right: "10px",
        padding: "8px",
        borderRadius: "8px",
        position: 'absolute',
        backgroundColor: '#E6FEFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    "& .focussedBigTimeContainer": {
        top: "10px",
        left: "10px",
        padding: "8px",
        borderRadius: "8px",
        position: 'absolute',
        backgroundColor: '#E6FEFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    "& .focussedBigStarBox": {
        top: "10px",
        right: "10px",
        padding: "8px",
        borderRadius: "3px",
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 28,
        height: 28,
        color: "var(--website-builder-primary-color)",

    },
    "& .focussedBigStar": {
        color: "var(--website-builder-primary-color)",
        width: '16.33px',
        height: '16.33px'

    },
    "& .focussedSmallStarBox": {
        top: "10px",
        right: "10px",
        padding: "8px",
        borderRadius: "3px",
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        color: "var(--website-builder-primary-color)",
    },
    "& .focussedSmallStar": {
        color: "var(--website-builder-primary-color)",
        width: '11.67px',
        height: '11.67px'

    },
    "& .duration": {
        fontSize: "9.88px",
        fontWeight: 500,
        lineHeight: "11.85px",
        color: '#1CC9D1'
    },
    "& .minutes": {
        fontSize: "6.91px",
        fontWeight: 400,
        lineHeight: "11.85px",
        color: '#1CC9D1'
    },
    "& .cardFooterConatiner": {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        paddingRight: "16px"
    },
    "& .focussedcardfooter": {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",

    },
    "& .bookNowButton": {
        width: "85.42px",
        height: "24.69px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "4px",
        cursor: "pointer",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)",
        fontSize: "9.88px"
    },
    "& .focussedplusButton": {
        width: "28px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .focussedBookNowButton": {
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: "9.88px",
        fontWeight: 500,
        lineHeight: "11.85px",
        whiteSpace: 'nowrap',
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .serviceBrief": {
        fontSize: "11px",
        lineHeight: "17.77px",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)"
    },
    "& .outerBox": {
        background: '#fff',
        border: "1px solid #22222226",
        borderRadius: 16,
        boxShadow: "0px 0px 10px 0px #0000000D",
        width: '202px',
        height: '171px',
        maxWidth: '32.2%'

    },
    "& .extraSectionCardLayout": {
        padding: '0.8rem',

    },
    "& .extraBoxTime": {
        background: "#2222220D",
        borderRadius: 6,
        width: 30,
        height: 13,
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
    },
    "& .extraServiceMinutes": {
        fontSize: "6px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "var(--website-builder-body-font)",
        color: '#1CC9D1',
    },
    "& .showMoreText": {
        color: "var(--website-builder-primary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        fontFamily: "var(--website-builder-body-font)",
        textTransform: 'none',
        textDecoration: 'underline',

    },
    "& .notMoreDescription": {
        color: "var(--website-builder-secondary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        fontFamily: "var(--website-builder-body-font)",

    },
    "& .extarCardDescription": {
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)",
        fontSize: '7px',
        lineHeight: '10.51px',
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        // whiteSpace:"nowrap",
        textOverflow: "ellipsis"
    },
    "& .extraBookNowButton": {
        textAlign: "center",
        backgroundColor: "var(--website-builder-primary-color)",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "6px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#ffffff",
        fontFamily: "var(--website-builder-body-font)",
        width: "59px",
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .extraPriceBox": {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "36px",
            fontFamily: "Rubik",
            color: "#000000",
        },
        "& .discountedPrice": {
            fontSize: "8px",
            fontWeight: 500,
            lineHeight: "12px",
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
        },
        "& .price": {
            color: "var(--website-builder-secondary-color)",
            fontFamily: "var(--website-builder-body-font)",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "18px !important"
        }
    },
}));

const HeaderServiceText = styled(Box)({
    fontSize: "8.89px",
    fontWeight: 600,
    fontFamily: "var(--website-builder-body-font)",
    color: "var(--website-builder-secondary-color)",
    whiteSpace: "nowrap"
});

const GridContainer = styled(Grid)({
    "& .navigationContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    "& .homeLink": {
        display: "flex",
        alignItems: "center",
        margin: "5px 0px"
    },
});

const HeaderContainer = styled(Box)({
    display: "flex",
    gap: "25px",
    margin: "5px 0px"
});

// Customizable Area End

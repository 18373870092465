import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
import React, { createRef } from "react"
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import {  Metadata, SectionDataInterfaceType, S as temp } from "./BrandingController.web"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { DropResult } from 'react-beautiful-dnd';
const baseURL = require("../../../framework/src/config.js").baseURL;
import { isJson } from "./helpers";import StorageProvider from "../../../framework/src/StorageProvider";
import mixpanel from "mixpanel-browser";
type SelectedFileTypeAlias = string | null | ArrayBuffer;
type SectionDataAlias = { [key: string]: SelectedDataTypeAlias };
type SelectedDataTypeAlias = string | null | ArrayBuffer | boolean;
import { resizeImage } from "../../utilities/src/imageVariantsResize";
import moment from "moment";

export interface HeaderData {
  bannerImg: string,
  name: string
}

interface HeaderTemplate {
  data: HeaderTemObj[]
}

export interface HeaderTemObj  {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    template_type: string;
    template_type_name: null;
  }
}
// Customizable Area End

export const configJSON = require("./config.js");

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    storeName: string;
    onTextChange?: (value: string) => void;
    selectedTab: string;
    parentStates: temp;
    selectedSection: string;
    updatedSection: string;
    updateSelectedSection: (sectionName: string) => void;
    updateExpandedSection: (sectionName: string) => void;
    createIdHandler: (createId: string, responseJson: ProductPreviewContentInterface) => void;
    realTimeHandler: (value: string | boolean, name: string, fieldType: string, fieldLinkType: string) => void;
    apiCalledHandler: () => void;
    realTimeSectionHandler: (value: any, fieldType: string, section_type: string, fieldName: string) => void;
    realTimeCategoryHandler: (fieldValue: any) => void;
    realTimeProductHandler: (fieldValue: any) => void;
    HandleStoreNameEnabled: (isStoreNameTrigged: boolean) => void;
    handleTestimonialNameEnabled: ( isTestimonialTrigged: boolean, index: number ) => void;
    handleTextLogoFontChange:(font:string)=>void;
    handleTextLogoColorChange:(color:string)=>void;
    HandleTextLogoData:()=>void;
    handleLocationNameEnabled: ( isLocationTrigged: boolean, index: number ) => void;
    HandleMobileMenyTrigger: (isMobileMenuOpenTrigger: boolean) => void;
    HandleLogoUpload: (isStoreNameTrigged: string) => void;
    handleSectionAdd: (type: string, name?: string) => void;
    updateSectionName: (id: string, sectionItemIndex: number, name?: string, section?: string) => void;
    deleteSection: (sectionItemIndex: number) => void;
    updateBannerMobileData: (index: number, base64Image: string, fileName: string, isBannerType: string) => void;
    updateBannerUrl: (index: number, bannerUrl: string) => void;
    updateBannerMobileMetadata: (index: number, metadata: any) => void;
    updateBannerDesktopMetadata: (index: number, metadata: any) => void;
    updateSectionType: (index: number, type: string) => void;
    handleSectionDropDownOpen: (index: number) => void;
    handleGetAPITrigger: () => void;
    handleCloseAllSections: () => void;
    handleSocialLinkToggle: (event: any) => void;
    overrideCategoryOrProductSelectedValue: (sectionId: string, sectionType: string, index: number, selectedValue: string) => void;
    updateFooter: (eventName: string, value: boolean | string) => void;
    HandleCanvasDataGetAPIResponse: (APIResponseData: any) => void;
    HandleNavigationMenuOptions: (navigationOptions: any) => void;
    realTimeSectionCategoryName: (sectionId: string, sectionName: string, section_type: string) => void;     
    handleBannerBackgroundColor: (color: string, curIndex: number) => void;
    handleBannerPosition: (position: string, curIndex: number) => void;
    handleNavigation: (navigationName: string, navigationType: string) => void;
    updatedArray: (updatedArray: SectionDataInterfaceType[] | unknown) => void;
    updateNavigationName: (navigationItems: HeaderMetadata) => void
    handleColors: (section: string, color: string) => void;
    handleText: (section: string, font: string) => void;
    resetSection: ()=> void;
    handleColorsSelection: (primary: string, secondary: string ) => void;
    handleHeaderTemplate: (name: string) =>  void;
    handleBannerTemplate: (id: number,item:any, index: number) => void;
    handleChangeFooterCopyRight: (event: React.ChangeEvent<HTMLInputElement>) => void;
    footerCopyRight: string;
    footerCopyRightError: string;
    resetFooterCopyRight: () => void
    updateFooterCopyRight:(newValCopyRight: string) => void
    // Customizable Area End
  };

export interface S {
  // Customizable Area Start
  activeRightSideBarTab: string,
  removeLogo: boolean,
  textLogoMetaData: { fontType: string, colour: string },
  isTextLogoModal:boolean,
  showStoreLinkInputBox: boolean,
  showContactInformation: boolean,
  showSocialLinks: boolean,
  selectedPhoneNumber: string | unknown,
  isDisabledSwitch: boolean,
  themeFontsContent: boolean,
  footerContent: boolean,
  headerContent: boolean,
  facebookUrl: string,
  errorStatus: boolean,
  checkStatus: boolean,
  instagramUrl: string,
  errorInstagramStatus: boolean,
  checkInstagramStatus: boolean,
  twitterUrl: string,
  errorTwitterStatus: boolean,
  checkTwitterStatus: boolean,
  youTubeUrl: string,
  errorYouTubeStatus: boolean,
  checkYouTubeStatus: boolean,
  linkedinUrl: string,
  errorLinkedinStatus: boolean,
  checkLinkedinStatus: boolean,
  playStoreUrl: string,
  errorPlayStoreStatus: boolean,
  checkPlayStoreStatus: boolean,
  appStoreUrl: string,
  errorAppStoreStatus: boolean,
  checkAppStoreStatus: boolean,
  copyRight: string,
  errorCopyRightStatus: boolean,
  createId: string,
  copyRightError: string,
  showProductPreview: ProductPreviewContentInterface;
  sectionDataN: Array<sectionData>
  contactTempInformation: boolean;
  appTempStore: boolean;
  socialTempMedia: boolean;
  facebookTempUrl: string,
  instagramTempUrl: string,
  twitterTempUrl: string,
  youTubeTempUrl: string,
  linkedinTempUrl: string,
  playStoreTempUrl: string,
  appStoreTempUrl: string,
  copyTempRight: string,
  sectionContent: boolean,
  selectTypeValue: string,
  cropperSectionOpen: boolean,
  selectedFile: SelectedFileTypeAlias,
  selectedCropedFile: SelectedFileTypeAlias,
  image: string;
  selectedFileType: string;
  uploadMobileBannerOpen: boolean;
  uploadHeaderLogoModalOpen: boolean;
  sectionImageSize: number;
  isBannerType: string;
  selectedModal: boolean;
  modalTabData: Array<string>;
  activeModalTabName: string;
  modalImages: Array<ModalImageData>;
  selectedImage: ModalImageData;
  sectionData: Array<SectionData>;
  addSectionBox: boolean;
  selectAddSectionType: string;
  selectAddSectionName: string;
  categoriesData: Array<string>;
  productsDisplayData: Array<string>;
  deleteSectionModal: boolean;
  deleteSectionItemIndex: number;
  selectMobileBanner: SelectedFileTypeAlias;
  selectDesktopBanner: SelectedFileTypeAlias;
  bannerUrl: string;
  checkBannerUrl: boolean;
  showStoreName: boolean,
  storeName: string;
  uploadModalType: string;
  HeaderCropSectionOpen: boolean;
  isLogoUploaded: boolean,
  isFaviconUploaded: boolean,
  SelectedLogoName: string | null,
  SelectedFaviconName: string | null,
  isMobileMenuOpen: boolean
  sectionMainData: Array<{ [key: string]: string | null | ArrayBuffer | boolean | undefined }>;
  sectionId: string;
  isDeleteSection: SectionDataAlias;
  allSelectValue: SectionDataAlias;
  tempBannerUrl: string;
  isPlayError: boolean;
  isAppError: boolean;
  isYoutubeError: boolean;
  isFacebookError: boolean
  isInstagramError: boolean;
  isTwitterError: boolean;
  isLinkedinError: boolean;
  selectedLogo: any,
  selectedFavicon: any,
  SelectedLogoFileType: string,
  SelectedFaviconFileType: string,
  SelectedLogoFileSize: number,
  SelectedFaviconFileSize: number,
  mainSideBarId: string,
  focusedIndex: any,
  isEditMode: boolean,
  selectedMobileFileName: string,
  selectedDesktopFileName: string
  isHeaderImageUploaded: boolean,
  isFieldSelected: boolean
  token: token
  isLoading: boolean
  isMobileMenuOpenChecker: boolean,
  showStoreNameChecker: boolean,
  sectionIndex: number,
  cropperHeight: number,
  cropperWidth: number,
  ratio: number,
  defaultColors: Array<{ primary: string, secondary: string, id: number }>,
  selectedColor: { primary: string, secondary: string, id: number },
  selectedFonts: { headerText: string, bodyText: string },
  isColorSelectionOpen: boolean
  isFontSelectionOpen: boolean
  isAddTextToBannerDrawerOpen: boolean
  bannerTextProperties: {
    mobile: {
      textAlignment: string,
      textPosition: string,
      headerText: string,
      fontColor: string,
      fontSize: string;
      fontFamily: string;
    }
    desktop: {
      textAlignment: string,
      textPosition: string,
      headerText: string,
      fontColor: string,
      fontSize: string;
      fontFamily: string;
    }
  }
  selectedDropdownIndex: number
  chosenBannerPlatform: 'desktop' | 'mobile'
  navigationItems: HeaderMetadata,
  manageToster: HeaderMetadata;
  showTestimonial: boolean;
  showLocation: boolean;
  showtestimonalNameCheck: boolean;
  testimonialName: string;
  testimonialError: boolean;
  isCustomFaviconSelected:boolean;
  selectImageType: string;
  searchValue: string;
  isFaviconEdited:boolean;
  errorLogoColor:boolean;
  isStoreNameOpenChecker: boolean;
  bannerBackgroundColor: string;
  bannerPosition: string;
  themeFonts:{
    primary_colour: string;
    secondary_colour: string;
    header_text: string;
    body_text: string;
  },
  section: Array<sectionData>;
  updatedArray: Array<SectionDataInterfaceType>;
  buildCardID: string;
  prevSelectedFavicon:{
    favicon:string;
    size:number;
    name:string;
    type:string;
  };
  textPlatform: string,
  bannerimageSelected: string;
  imageVariants: any;
  imageVariantsIndex:number;
  isHeaderOpen: boolean;
  selectedHeader: string;
  selectHeader: string;
  headerTemplate: Array<HeaderTemObj>;
  templeteId: string;
  headerSelected: string | undefined;
  selectedCatalogue: number;
  isBannerOpen: boolean;
  activeNumber:number;
  selectedSection:string
  // Customizable Area End
}

// Customizable Area Start
export interface CategoryData {
  category_image: string,
  category_name: string
}

export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}

export interface StaffMetaData {
  selected: string,
  staffAttrs: {
    id: string,
    type: string,
    attributes: {
      id: number,
      full_name: string,
      designation: string,
      slug: string,
      image: string
    }
  }
}

export interface HeaderMetadata {
  nevigationItem1: string,
  nevigationItem2: string,
  nevigationItem3: string
}

interface ImageAttributes {
  name: string;
  created_at: string;
  updated_at: string;
  url: string;
  category: string;
  sub_category: string;
}

interface ImageData {
  id: string;
  type: string;
  attributes: ImageAttributes;
}

interface ApiResponse {
  data: ImageData[];
}

interface ModalImage {
  type: string;
  name: string;
  url: string;
  isSelect: boolean;
  category: string;
  sub_category: string;
}

export type BannerVariants = {
  desktop_banner_variant_768?: any;
  desktop_banner_variant_1280?: any;
  desktop_banner_variant_1920?: any;
  mobile_banner_variant_360?: any;
  mobile_banner_variant_414?: any;
  mobile_banner_variant_601?: any;
};

export type sectionData = {
  id: string;
  type: string;
  attributes: {
    section_type: string;
    section_name: string;
    banner_url: string;
    mobile_banner_url: string;
    desktop_banner_url: string;
    desktop_banner_metadata: any;
    mobile_banner_filename: string;
    mobile_banner_metadata: any;
    is_deleted: boolean;
    metadata: Metadata;
    desktopBannerVariants: BannerVariants;
    mobileBannerVariants: BannerVariants;
  }
};

export interface HeaderNavigation {
  data: {
    id: string,
    type: string,
    attributes: {
      show_business_name: boolean,
      is_mobile_menu: boolean,
      logo_url: null,
      favicon_url: null,
      logo_filename: string,
      favicon_filename: string,
      metadata: string,
      template_details?: {
        id?: number;
        name?: string;
        template_type?: string;
      }
    }
  }
}


export interface ProductPreviewContentInterface {

  footer: {
    phone_number: number;
    copyright: string;
    facebook_url: string;
    x_url: string;
    youtube_url: string;
    linkedin_url: string;
    appstore_url: string;
    playstore_url: string;
    instagram_url: string;
    design_id: string;
    is_social_media: boolean;
    is_app_link: boolean;
    is_contact: boolean;
  };
  metadata: {
    catagoryData: Array<{ [key: string]: string }>,
    sectionUpdateData: Array<{ [key: string]: string }>,
    selectedValue: { [key: string]: string },
    headerNavigationData?: any
  };
  sections: {
    data: Array<sectionData>
  };
  header: {
    isLogoUploaded: boolean,
    isFaviconUploaded: boolean,
    is_mobile_menu: boolean,
    selectedLogo: null | string,
    selectedFavicon: null | string,
    show_business_name: boolean,
    logo_url?: string,
    favicon_url?: string,
    logo_filename?: string,
    favicon_filename?: string,
    custom_favicon:boolean;
    logo_colour:string | null;
    logo_font:string | null;
    templateId?: number;
    templateDetails?: {
      id?: number;
      name?: string;
      templateType?: string;
    }
  };
  themes_and_font: {
    primary_colour: string;
    secondary_colour: string;
    header_text: string;
    body_text: string;
  }

}

type token = string | null
export interface SectionData { [key: string]: string | boolean }
export interface ModalImageData {
  type: string;
  name: string;
  url: string;
  isSelect: boolean;
  category: string;
  sub_category: string;
}
export interface ValidResponseType {
  data: object;
}
export interface SS {
  id: string;
}
// Customizable Area End

class ContactInformationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postCreateWebsiteBuilderCallId: string = "";
  putWebsiteBuilderCallId: string = "";
  getProductPreviewContentCallId: string = ""
  getImageCatalogueAPI: string = "";
  getDefaultColorsAPI: string = "";
  scrollRef: React.RefObject<Scrollbars>;
  inputRef: any;
  imgRef?: React.RefObject<HTMLImageElement>;
  debounceTimeout: NodeJS.Timeout | undefined;
  getHeaderTemplateCallId: string = "";
  getStoreDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.inputRef = React.createRef();
    this.imgRef = React.createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      activeRightSideBarTab: "Footer",
      removeLogo:false,
      showContactInformation: false,
      showStoreLinkInputBox: false,
      showSocialLinks: false,
      selectedPhoneNumber: "",
      isDisabledSwitch: true,
      footerContent: false,
      facebookUrl: "",
      errorStatus: false,
      checkStatus: false,
      instagramUrl: "",
      errorInstagramStatus: false,
      checkInstagramStatus: false,
      twitterUrl: "",
      errorTwitterStatus: false,
      checkTwitterStatus: false,
      youTubeUrl: "",
      errorYouTubeStatus: false,
      checkYouTubeStatus: false,
      linkedinUrl: "",
      errorLinkedinStatus: false,
      checkLinkedinStatus: false,
      playStoreUrl: "",
      errorPlayStoreStatus: false,
      checkPlayStoreStatus: false,
      appStoreUrl: "",
      errorAppStoreStatus: false,
      checkAppStoreStatus: false,
      copyRight: "",
      errorCopyRightStatus: false,
      createId: "",
      copyRightError: "",
      showProductPreview: {
        header: {
          isLogoUploaded: false,
          isFaviconUploaded: false,
          selectedLogo: null,
          selectedFavicon: null,
          is_mobile_menu: false,
          show_business_name: false,
          custom_favicon:false,
          logo_colour:null,
          logo_font:null,
          templateId: NaN,
          templateDetails: {
            id: NaN,
            name: "",
            templateType: "",
          }
        },
        metadata: {
          catagoryData: [],
          sectionUpdateData: [],
          selectedValue: {}
        },
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                section_type: "",
                section_name: "",
                banner_url: "",
                mobile_banner_url: "",
                desktop_banner_url: "",
                desktop_banner_metadata: "",
                mobile_banner_filename: "",
                mobile_banner_metadata: "",
                is_deleted: false,
                metadata: {
                  isOpen: false
                },
                desktopBannerVariants: {},
                mobileBannerVariants: {},
              }
            },
            {
              id: "",
              type: "",
              attributes: {
                section_type: "",
                section_name: "",
                banner_url: "",
                mobile_banner_url: "",
                desktop_banner_url: "",
                desktop_banner_metadata: "",
                mobile_banner_filename: "",
                mobile_banner_metadata: "",
                is_deleted: false,
                metadata: {
                  isOpen: false
                },
                desktopBannerVariants: {},
                mobileBannerVariants: {},
              }
            }
          ]
        },
        footer: {
          phone_number: 1,
          copyright: "",
          facebook_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          instagram_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false,
        },
        themes_and_font: {
          primary_colour: "",
          secondary_colour: "",
          header_text: "",
          body_text: ""
        }
      },
      sectionDataN: [],
      contactTempInformation: false,
      appTempStore: false,
      socialTempMedia: false,
      facebookTempUrl: "",
      instagramTempUrl: "",
      twitterTempUrl: "",
      youTubeTempUrl: "",
      linkedinTempUrl: "",
      playStoreTempUrl: "",
      appStoreTempUrl: "",
      copyTempRight: "Copyright � Current Year Store Name. All rights reserved.",
      sectionContent: false,
      selectTypeValue: "",
      cropperSectionOpen: false,
      selectedFile: null,
      selectedCropedFile: null,
      image: "",
      selectedFileType: "image/jpeg",
      uploadMobileBannerOpen: false,
      sectionImageSize: 0,
      isBannerType: "",
      selectedModal: false,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
        category: "",
        sub_category: "",
      },
      modalTabData: ["Catalogue", "Upload image"],
      activeModalTabName: "Catalogue",
      modalImages: [
        {
          type: "",
          name: "",
          url: "",
          isSelect: false,
          category: "",
          sub_category: "",
        },
      ],
      sectionData: [],
      categoriesData: ["Formal", "Casual", "Apparel", "Shirt"],
      productsDisplayData: ["AIR JORDAN 1 MID", "AIR JORDAN 12", "AIR JORDAN 1 RTS", "AIR JORDAN 2D"],
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: "",
      deleteSectionModal: false,
      deleteSectionItemIndex: -1,
      selectMobileBanner: "",
      selectDesktopBanner: "",
      bannerUrl: "",
      uploadHeaderLogoModalOpen: false,
      themeFontsContent: false,
      headerContent: false,
      uploadModalType: "",
      HeaderCropSectionOpen: false,
      isLogoUploaded: false,
      isFaviconUploaded: false,
      SelectedLogoName: "",
      SelectedFaviconName: "",
      isMobileMenuOpen: false,
      selectedLogo: null,
      selectedFavicon: null,
      SelectedLogoFileType: "",
      SelectedLogoFileSize: 0,
      SelectedFaviconFileType: "",
      SelectedFaviconFileSize: 0,
      showStoreName: false,
      storeName: '',
      isHeaderImageUploaded: false,
      token: '',
      isLoading: false,
      isMobileMenuOpenChecker: false,
      showStoreNameChecker: false,

      checkBannerUrl: false,
      sectionMainData: [],
      sectionId: "",
      isDeleteSection: {},
      allSelectValue: {},
      tempBannerUrl: "",
      isPlayError: false,
      isAppError: false,
      isYoutubeError: false,
      isFacebookError: false,
      isInstagramError: false,
      isTwitterError: false,
      isLinkedinError: false,
      mainSideBarId: "",
      focusedIndex: null,
      isEditMode: false,
      selectedMobileFileName: "",
      selectedDesktopFileName: "",
      isFieldSelected: false,
      sectionIndex: 0,
      cropperHeight: 0,
      cropperWidth: 0,
      ratio: 0,
      defaultColors: [],
      selectedColor: { primary: "", secondary: "", id: 0 },
      selectedFonts: { headerText: "", bodyText: "" },
      isColorSelectionOpen: false,
      isFontSelectionOpen: false,
      isAddTextToBannerDrawerOpen: false,
      bannerTextProperties: {
        mobile: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
          fontFamily: ""
        },
        desktop: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
          fontFamily: ""
        }
      },
      selectedDropdownIndex: 0,
      chosenBannerPlatform: 'desktop',
      navigationItems: {} as HeaderMetadata,
      manageToster: {} as HeaderMetadata,
      showTestimonial: false,
      showLocation: false,
      showtestimonalNameCheck: false,
      testimonialName: "",
      testimonialError: false,
      isCustomFaviconSelected:false,
      selectImageType: "all",
      searchValue: "",
      isFaviconEdited:false,
      isTextLogoModal:false,
      textLogoMetaData: { fontType: "Lato, sans-serif", colour: "#000" },
      errorLogoColor: false,
      isStoreNameOpenChecker: false,
      bannerBackgroundColor: "",
      bannerPosition: "",
      themeFonts:{
        primary_colour: "",
        secondary_colour: "",
        header_text: "",
        body_text: ""
      },
      section: [],
      updatedArray: [],
      buildCardID: "",
      prevSelectedFavicon:{
        name:"",
        size:0,
        favicon:"",
        type:"",
      },
      textPlatform: "",
      bannerimageSelected: "",
      imageVariants: [],
      imageVariantsIndex:0,
      isHeaderOpen: false,
      selectedHeader: "",
      selectHeader: "",
      headerTemplate: [],
      templeteId: "",
      headerSelected: "",
      selectedCatalogue: NaN,
      isBannerOpen: false,
      activeNumber:0,
      selectedSection:""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  receive = async (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageCreateContent(message)
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageUpdateContent(message)
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIMessageShowContent(message)
    }

    if (this.getImageCatalogueAPI === apiRequestCallId) {
      this.handleImageCatalogResponse(responseJson)

    }

    if (this.getDefaultColorsAPI === apiRequestCallId) {
      this.handleDefaultColorsResponse(responseJson)
    }
    
    if (this.getStoreDetailsApiCallId === apiRequestCallId) {
      this.handleStoreDetailsResponse(responseJson)
    }

    if (this.getHeaderTemplateCallId === apiRequestCallId) {
      this.handleHeaderResponse(responseJson)
    }

    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );

      if (type === "SAVECHANGES") {
      this.saveChangesConditions()
      
      }
      if (type === "DISCARDCHANGES") {
        this.resetSectionData()
        this.resetFooterData()
        this.resetHeaderData()
        this.getProductPreview()
        this.props.resetSection()
      }
      this.closeColorPicker();
    }
  }

  closeColorPicker = () => {
    const headerSectionTitle = document.querySelector('[data-test-id=ThemeFontsSectionId') as HTMLElement;
    if (headerSectionTitle) {
      headerSectionTitle.click()
      setTimeout(() => {
        headerSectionTitle.click()
      }, 0)
    }
  }

  onChangeBannerTextProperty = (name: string, value: string, platform: 'desktop' | 'mobile') => {
    if (name === "headerText") {
      value = value.slice(0, 40)
      if (value.split('\n').length === 4) {
        value = value.slice(0, value.length - 1)
      }
    }
    
    this.setState({
      bannerTextProperties: {
        ...this.state.bannerTextProperties,
        [platform]: {
          ...this.state.bannerTextProperties[platform],
          [name]: value
        }
      },
      textPlatform: platform
    }, () => {
      const { selectedDropdownIndex, bannerTextProperties } = this.state
      const getBannerMeta = (platform: 'desktop' | 'mobile') => ({
        text_alignment: bannerTextProperties[platform].textAlignment,
        text_position: bannerTextProperties[platform].textPosition,
        header_text: bannerTextProperties[platform].headerText,
        font_colour: bannerTextProperties[platform].fontColor,
        font_size: bannerTextProperties[platform].fontSize,
        font_family: bannerTextProperties[platform].fontFamily
      })
      this.props.updateBannerMobileMetadata(selectedDropdownIndex, getBannerMeta('mobile'))
      this.props.updateBannerDesktopMetadata(selectedDropdownIndex, getBannerMeta('desktop'))
    });
  }

  revertChangesForBannerText = () => {
    const { selectedDropdownIndex, showProductPreview, chosenBannerPlatform, section } = this.state;
    let bannerMetadataBase = section[selectedDropdownIndex]?.attributes || [];
    let bannerMetaValue = bannerMetadataBase[chosenBannerPlatform === 'desktop' ? 'desktop_banner_metadata' : 'mobile_banner_metadata']
    
    bannerMetaValue = isJson(bannerMetaValue) ? JSON.parse(bannerMetaValue) : bannerMetaValue
    const getMetaBanner = () => {
      const { text_alignment , text_position , header_text , font_colour , font_size , font_family } = bannerMetaValue || {};
      return {
        text_alignment,
        text_position,
        header_text,
        font_colour,
        font_size,
        font_family
      }
    }
    if (chosenBannerPlatform === 'desktop') {
      this.setState({
        bannerTextProperties: {
          ...this.state.bannerTextProperties,
          desktop: {
            textAlignment: bannerMetaValue?.text_alignment,
            textPosition: bannerMetaValue?.text_position,
            headerText: bannerMetaValue?.header_text,
            fontColor: bannerMetaValue?.font_colour,
            fontSize: bannerMetaValue?.font_size,
            fontFamily: bannerMetaValue?.font_family
          }
        }
      })
      this.props.updateBannerDesktopMetadata(selectedDropdownIndex, getMetaBanner())
    } else {
      this.setState({
        bannerTextProperties: {
          ...this.state.bannerTextProperties,
          mobile: {
            textAlignment: bannerMetaValue?.text_alignment,
            textPosition: bannerMetaValue?.text_position,
            headerText: bannerMetaValue?.header_text,
            fontColor: bannerMetaValue?.font_colour,
            fontSize: bannerMetaValue?.font_size,
            fontFamily: bannerMetaValue?.font_family
          }
        }
      })
      this.props.updateBannerMobileMetadata(selectedDropdownIndex, getMetaBanner())
    }
    this.toggleAddTextToBannerDrawer(false, chosenBannerPlatform)
  }

  toggleAddTextToBannerDrawer = (open: boolean, platform: 'desktop' | 'mobile') => {
    this.setState({
      isAddTextToBannerDrawerOpen: open,
      chosenBannerPlatform: platform,
    });
  }

  getDefaultColors = async () => {
    this.getDefaultColorsAPI = this.apiCallHandle(this.state.token, configJSON.defaultColorsApiEndPoint, configJSON.getApiMethod)
  }

  handleDefaultColorsResponse = (responseJson: any) => {
    if (responseJson?.data) {
      let defaultColors: any = []
      responseJson.data.map((item: any) => {
        defaultColors.push({
          primary: item.attributes.primary_colour,
          secondary: item.attributes.secondary_colour,
          id: item.id
        })
      })
      this.setState({
        defaultColors
      })
    }
  }

  handleColorSelection = (color: { primary: string, secondary: string, id: number }) => {
    document.documentElement.style.setProperty('--primary-color', color.primary);
    document.documentElement.style.setProperty('--secondary-color', color.secondary);
    this.setState({
      selectedColor: color
    }, this.openToastChange)
    this.props.handleColorsSelection(color.primary, color.secondary)
  }

  handleHeaderTextFontChange = (font: string) => {
    document.documentElement.style.setProperty('--header-text-font', font);
    this.setState({
      selectedFonts: {
        ...this.state.selectedFonts,
        headerText: font
      }
    }, this.openToastChange)
    this.props.handleText("header", font)
  }

  handleBodyTextFontChange = (font: string) => {
    document.documentElement.style.setProperty('--body-text-font', font);
    this.setState({
      selectedFonts: {
        ...this.state.selectedFonts,
        bodyText: font
      }
    }, this.openToastChange)
    this.props.handleText("body", font)
  }

  handlePrimaryColorChange = (color: string) => {
    document.documentElement.style.setProperty('--primary-color', color);
    this.setState({
      selectedColor: {
        ...this.state.selectedColor,
        primary: color
      }
    }, this.openToastChange
  )
  this.props.handleColors("primary", color)
  }

  handleSecondaryColorChange = (color: string) => {
    document.documentElement.style.setProperty('--secondary-color', color);
    this.setState({
      selectedColor: {
        ...this.state.selectedColor,
        secondary: color
      }
    }, this.openToastChange)
    this.props.handleColors("secondary", color)
  }

  handleCustomColorSelection = () => {
    if (!this.state.selectedColor.id) {
      return;
    }
    this.setState({
      selectedColor: { primary: "", secondary: "", id: 0 }
    }, this.openToastChange)
  }
  handleTextLogoFontChange = (font: string) => {
    this.props.handleTextLogoFontChange(font);
    this.openToastChange()
  }
  handleTextLogoColorChange = (color: string) => {
    if (!color) return;
    const hexValidated = /^#[0-9A-F]{6}$/i.test(color)
    this.props.handleTextLogoColorChange(color);
    this.setState({
      errorLogoColor: !hexValidated
    })

  }
  toggleColorSelectionOpen = () => {
    this.setState({
      isColorSelectionOpen: !this.state.isColorSelectionOpen
    })
  }

  toggleFontSelectionOpen = () => {
    this.setState({
      isFontSelectionOpen: !this.state.isFontSelectionOpen
    })
  }

  saveChangesConditions(){
    let createId = "4"

    let { errorStatus, errorInstagramStatus,
      errorTwitterStatus, errorLinkedinStatus,
      errorYouTubeStatus, errorPlayStoreStatus, errorAppStoreStatus, testimonialName
      } = this.state
    if (!errorStatus && !errorInstagramStatus && !errorTwitterStatus && !errorLinkedinStatus &&
      !errorYouTubeStatus && !errorPlayStoreStatus && !errorAppStoreStatus && this.props.footerCopyRightError !== "Copyright must be at maximum of 150 characters" && this.checkEmptyValidationsSocial()
      && this.checkEmptyValidationsLink()
    ) {
      createId ? this.updateWebsiteBuilderContent() : this.createWebsiteBuilderContent()
    }
    else {
    this.validationConditionsCheck()
    }
  }
  validationConditionsCheck(){
    let { playStoreUrl, appStoreUrl } = this.state
    if (!this.checkEmptyValidationsLink()) {
      this.setState({
        isPlayError: playStoreUrl ? false : true,
        isAppError: appStoreUrl ? false : true,
      })
    } if (!this.checkEmptyValidationsSocial()) {
      this.validationConditionCheck2()
    }
  }
  validationConditionCheck2(){
    let {  youTubeUrl, linkedinUrl, facebookUrl, instagramUrl, twitterUrl } = this.state
    this.setState({
      isLinkedinError: linkedinUrl ? false : true,
      isYoutubeError: youTubeUrl ? false : true,
      isFacebookError: facebookUrl ? false : true,
      isInstagramError: instagramUrl ? false : true,
      isTwitterError: twitterUrl ? false : true,
    })
  }
  checkEmptyValidationsSocial() {
    if (this.state.showSocialLinks) {
      if (this.state.youTubeUrl || this.state.linkedinUrl || this.state.facebookUrl || this.state.instagramUrl || this.state.twitterUrl) {
        return true
      } else { return false }
    } else {
      return true
    }
  }

  checkEmptyValidationsLink() {
    if (this.state.showStoreLinkInputBox) {
      if ((this.state.playStoreUrl || this.state.appStoreUrl)) {
        return true
      } else {
        return false
      }
    }
    else {
      return true
    }
  }

  checkEmptyValidations() {
    if ((this.state.showStoreLinkInputBox)
    ) {
      if ((this.state.playStoreUrl || this.state.appStoreUrl)) {
        this.setState({
          isPlayError: false,
          isAppError: false,
        })
      }
    }
    if ((this.state.showSocialLinks)) {
    this.updateStateForMain()
    } else {

      if (!this.checkEmptyValidationsLink()) {
        this.updateState();
      } if (!this.checkEmptyValidationsSocial()) {
      this.updateStateForElse();
      }
    }
  }

  updateStateForMain(){
    if (this.state.youTubeUrl || this.state.linkedinUrl || this.state.facebookUrl || this.state.instagramUrl || this.state.twitterUrl) {
      this.setState({
        isLinkedinError: false,
        isYoutubeError: false,
        isFacebookError: false,
        isInstagramError: false,
        isTwitterError: false,
      })
    }
  }
  updateState(){
    this.setState({
      isPlayError: this.state.playStoreUrl ? false : true,
      isAppError: this.state.appStoreUrl ? false : true,
    })
  }

  updateStateForElse(){
    this.setState({
      isLinkedinError: this.state.linkedinUrl ? false : true,
      isYoutubeError: this.state.youTubeUrl ? false : true,
      isFacebookError: this.state.facebookUrl ? false : true,
      isInstagramError: this.state.instagramUrl ? false : true,
      isTwitterError: this.state.twitterUrl ? false : true,
    })
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.parentStates.categoriesData !== prevProps.parentStates.categoriesData) {
      const categoriesOptions: { value: string, label: string }[] = this.props.parentStates.categoriesData.map((item: { category_name: string, id: string }) => {
        return {
          value: item.id,
          label: item.category_name
        }
      });
    }
  }
  async componentDidMount() {
    let id = await getStorageData("id")
    const token = await getStorageData("admintoken")
    const buildcardName = await StorageProvider.get("buildCardID");
    this.setState({
        buildCardID: buildcardName
    });
    this.setState({
      mainSideBarId: id,
      token
    }, () => { 
      this.getProductPreview();
     })
    if (token) {
      this.setState({
        isLoading: true
      })
    }
    //  this.getStoreDetails()
  };

  resetSectionData = () => {
    this.setState({
      sectionContent: false,
      selectTypeValue: "",
      cropperSectionOpen: false,
      selectedFile: null,
      selectedCropedFile: null,
      image: "",
      uploadMobileBannerOpen: false,
      sectionImageSize: 0,
      isBannerType: "",
      selectedModal: false,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
        category: "",
        sub_category: "",
      },
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: "",
      deleteSectionModal: false,
      selectMobileBanner: "",
      selectDesktopBanner: "",
      bannerUrl: "",
      checkBannerUrl: false,
      selectedMobileFileName: "",
      allSelectValue: {},
      sectionData: [],
    })
  }

  resetFooterData = () => {
    this.setState({
      showStoreLinkInputBox: false,
      showSocialLinks: false,
      // copyRight: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      youTubeUrl: "",
      linkedinUrl: "",
      playStoreUrl: "",
      showContactInformation: false,
      checkPlayStoreStatus: false,
      // copyRightError: "",
      checkInstagramStatus: false,
      checkTwitterStatus: false,
      checkYouTubeStatus: false,
      checkLinkedinStatus: false,
      checkAppStoreStatus: false,
      errorStatus: false,
      appStoreUrl: ""
    })
    this.props.resetFooterCopyRight()
  };

  resetHeaderData = () => {
    this.setState({
      uploadHeaderLogoModalOpen: false,
      uploadModalType: "",
      HeaderCropSectionOpen: false,
      isLogoUploaded: false,
      isFaviconUploaded: false,
      SelectedLogoName: "",
      SelectedFaviconName: "",
      isMobileMenuOpen: false,
      selectedLogo: null,
      selectedFavicon: null,
      SelectedLogoFileType: "",
      SelectedLogoFileSize: 0,
      SelectedFaviconFileType: "",
      SelectedFaviconFileSize: 0,
      showStoreName: false,
      storeName: '',
    },()=>{
      this.props.HandleMobileMenyTrigger(this.state.isMobileMenuOpen);
      this.props.handleHeaderTemplate(this.state?.headerSelected as string)
    })
  }

  handleSectionMainData = (sectionId: string, sectionType: string, sectionName: string, fieldName: string, fieldValue: SelectedFileTypeAlias, fileName?: any, fileValue?: string) => {
    let sectionMainData = this.state.sectionMainData.slice();
    if (sectionMainData.length === 0) {
      sectionMainData[0] = {
        section_type: sectionType,
        section_name: sectionName,
        [fieldName]: fieldValue,
        [fileName]: fileValue,
        is_Deleted: false,
        sectionId
      };
    } else {
      let mainData = sectionMainData.find(data => data.sectionId === sectionId);
      if (mainData) {
        let matchedIndex = sectionMainData.findIndex(data => data.sectionId === sectionId);
        let newData = { ...mainData, [fieldName]: fieldValue, [fileName]: fileValue };
        sectionMainData[matchedIndex] = newData;
      } else {
        sectionMainData.push({
          section_type: sectionType,
          section_name: sectionName,
          [fieldName]: fieldValue,
          [fileName]: fileValue,
          is_Deleted: false,
          sectionId
        });
      }
    }
    this.setState({
      sectionMainData,
      allSelectValue: {
        ...this.state.allSelectValue,
        [sectionId + fieldName]: fieldValue
      }
    }, () => {
      if(typeof fieldValue === "string"){
        this.props.overrideCategoryOrProductSelectedValue(sectionId, sectionType, fileName, fieldValue);
      }else{
        this.props.overrideCategoryOrProductSelectedValue(sectionId, sectionType, fileName, "");
      }
      this.openToastChange();
    });
  };

  sectionDataModify = () => {
    let categoryData = this.state.sectionMainData.filter((item) => {
      return item.section_type === "category";
    })[0];

    let CategoryList = [];
    for (const key in categoryData) {
      if (key.startsWith("category")) {
        CategoryList.push({
          category_name: categoryData[key],
          category_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg"
        });
      }
    }
    return CategoryList;
  }

  handleBannerUrl = (event: React.ChangeEvent<HTMLInputElement>, sectionId: string, section_type: string, section_name: string, index: number) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const bannerUrlRegex = /^(www.|http:\/\/|https:\/\/)/;
    ;
    this.setState({
      bannerUrl: trimmedValue,
    }, () => {
      if (this.state.bannerUrl === this.state.tempBannerUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (bannerUrlRegex.test(this.state.bannerUrl)) {
        this.props.realTimeHandler(this.state.bannerUrl, name, "sections", "")
        this.handleSectionMainData(sectionId, section_type, section_name, name, value)
        this.setState({
          checkBannerUrl: false
        })
      } else {
        if (!this.state.bannerUrl) {
          this.setState({
            checkBannerUrl: false
          })
        } else {
          this.setState({
            checkBannerUrl: true
          })
        }
      }
      this.props.updateBannerUrl(index, this.state.bannerUrl)
    })
  }

  handleMobileBannerMetadata = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;

    const mobileBannerMetadata = {
      text_alignment: bannerTextProperties.mobile.textAlignment,
      header_text: bannerTextProperties.mobile.headerText,
      font_colour: bannerTextProperties.mobile.fontColor,
      font_size: bannerTextProperties.mobile.fontSize,
      font_family: bannerTextProperties.mobile.fontFamily
    }
    this.props.updateBannerMobileMetadata(selectedDropdownIndex, JSON.stringify(mobileBannerMetadata))
    this.saveChangesConditions()
    this.toggleAddTextToBannerDrawer(false, 'mobile')
    this.closeToastChange()
  }

  handleDesktopBannerMetadata = () => {
    const { bannerTextProperties, selectedDropdownIndex } = this.state;

    const desktopBannerMetadata = {
      header_text: bannerTextProperties.desktop.headerText,
      font_colour: bannerTextProperties.desktop.fontColor,
      font_size: bannerTextProperties.desktop.fontSize,
      font_family: bannerTextProperties.desktop.fontFamily
    }
    this.props.updateBannerDesktopMetadata(selectedDropdownIndex, JSON.stringify(desktopBannerMetadata))
    this.saveChangesConditions()
    this.toggleAddTextToBannerDrawer(false, 'desktop')
    this.closeToastChange()
  }

  handleSectionCropperClose = () => {
    this.setState({
      cropperSectionOpen: false
    }, () => {
      this.setState({
        uploadMobileBannerOpen: true
      })
    })
  };

  HandleTextLogoData = ()=>{
    if (this.state.errorLogoColor) { return }
    this.props.HandleTextLogoData()
    this.setState({ isTextLogoModal:false}, ()=>{
      this.openToastChange()
    })
  }
  handleSectionClose = () => {
    this.setState({
      uploadMobileBannerOpen: false,
      isHeaderOpen:false
    })
  };

  addSection = () => {
    this.setState({ 
      addSectionBox: true, 
      selectedDropdownIndex: this.props.parentStates.SectionData.length,
      bannerTextProperties: {
        mobile: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
          fontFamily: ""
        },
        desktop: {
          textAlignment: "",
          textPosition: "",
          headerText: "",
          fontColor: "",
          fontSize: "",
          fontFamily: ""
        }
      }
    }, () => {
      this.props.handleCloseAllSections()
    });
  }

  removeAddSectionBox = () => {
    this.setState({
      addSectionBox: false
    });
  }

  handleSelectCatalogue = () => {
    const { modalImages, token } = this.state;
    const updatedImages = modalImages.map(image => ({
      ...image,
      isSelect: false,
    }));
    this.getImageCatalogueAPI = this.apiCallHandle(token, `${configJSON.getImageCatalougeAPIEndpoint}filter_by=all&keyword=`, configJSON.getApiMethod)
    this.setState({
      isLoading: true,
      modalImages: updatedImages,
      selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
        category: "",
        sub_category: "",
      },
      selectedModal: true,
    }, () => ({
      uploadMobileBannerOpen: true,
    }))
  }
  
  setImageTypeBox = (type: string) => {
    this.setState({selectImageType : type, isLoading : true}, ()=>{
      this.getImageCatalogueAPI = this.apiCallHandle(this.state.token, `${configJSON.getImageCatalougeAPIEndpoint}filter_by=${this.state.selectImageType}&keyword=${this.state.searchValue}`, configJSON.getApiMethod)
    })
  }
  toggleDropDown = (dropDownIndex: number, fromBanner: boolean = false) => {
    if (fromBanner) {
      let mobileBannerMetadata = this.state.section[dropDownIndex]?.attributes.mobile_banner_metadata;
      let desktopBannerMetadata = this.state.section[dropDownIndex]?.attributes.desktop_banner_metadata;
      mobileBannerMetadata = isJson(mobileBannerMetadata) ? JSON.parse(mobileBannerMetadata) : mobileBannerMetadata
      desktopBannerMetadata = isJson(desktopBannerMetadata) ? JSON.parse(desktopBannerMetadata) : desktopBannerMetadata
      this.setState({
        bannerTextProperties: {
          mobile: {
            textAlignment: mobileBannerMetadata?.text_alignment || "",
            textPosition: mobileBannerMetadata?.text_position || "",
            headerText: mobileBannerMetadata?.header_text || "",
            fontColor: mobileBannerMetadata?.font_colour || "",
            fontSize: mobileBannerMetadata?.font_size || "",
            fontFamily: mobileBannerMetadata?.font_family || ""
          },
          desktop: {
            textAlignment: desktopBannerMetadata?.text_alignment || "",
            textPosition: desktopBannerMetadata?.text_position || "",
            headerText: desktopBannerMetadata?.header_text || "",
            fontColor: desktopBannerMetadata?.font_colour || "",
            fontSize: desktopBannerMetadata?.font_size || "",
            fontFamily: desktopBannerMetadata?.font_family || ""
          }
        }
      })
    }
    this.setState({ selectedDropdownIndex: dropDownIndex })
    this.props.handleSectionDropDownOpen(dropDownIndex)
    this.updateSectionState(`section${dropDownIndex}Unique`)
  }

  typeSelectHandler = (index: number, type: string) => {
    let sectionNewData = this.state.sectionData.map((item: SectionData, itemIndex: number) => {
      if (index === itemIndex) {
        return {
          ...item,
          type
        }
      }
      return item
    })
    this.setState({
      sectionData: sectionNewData,
      isBannerOpen: true
    }, () => {
      this.openToastChange();
      this.getHeaderTemplate(type);
      this.props.updateSectionType(index, type)
    })
  };

  addNewSection = (event: any,index: number) => {
    const { selectAddSectionName, sectionData } = this.state;
    const { isBannerEnable } = this.props.parentStates;
    let name = selectAddSectionName;
    let activeNumber;

    if (event.target.value === "staff_display") {
      activeNumber = 1;
    } else if (event.target.value === "service_display") {
      activeNumber = 2;
    } else {
      activeNumber = 0;
    }
    if (event.target.value === "banner") {
      name = "banner";
    }

    const newSection = {
      name: name,
      isOpen: true,
      type: event.target.value,
      sectionId: "id_" + this.generateRandomString(11) + "_" + Date.now()
    };

    const updatedSectionData = [...sectionData, newSection];

    this.setState({
      isBannerOpen: true,
      sectionData: updatedSectionData,
      addSectionBox: false,
      selectAddSectionType: "",
      selectAddSectionName: "",
      selectedDropdownIndex: index, 
      activeNumber: activeNumber, 
      selectedSection: event.target.value
    }, () => { 
      this.props.handleSectionAdd(event.target.value, name);
      this.getHeaderTemplate(event.target.value);
      this.openToastChange();
      if(!isBannerEnable){
        this.updateSectionState(`section${this.state.selectedDropdownIndex}Unique`)
      }
    });
  }

  changeSectionName = (event: React.ChangeEvent<HTMLInputElement>, sectionId: string, sectionItemIndex: number, section_name: string, section: string) => {
    let sectionName = event.target.value;
    this.props.realTimeSectionCategoryName(sectionId, sectionName, section_name)
    this.handleSectionMainData(sectionId, sectionName, section_name, "section_type", sectionName)
    const copySectionData = [...this.state.sectionData];
    let updatedSectionItem = copySectionData[sectionItemIndex];
    updatedSectionItem = {
      ...updatedSectionItem,
      name: sectionName
    }
    copySectionData[sectionItemIndex] = updatedSectionItem;
    this.setState({
      sectionData: copySectionData
    }, () => { this.props.updateSectionName(sectionId, sectionItemIndex, sectionName, section); })
  }

  handleCropperSubmit = async(data: string) => {
    const imageVariant = await resizeImage(data);
    const { sectionIndex, isBannerType, selectedMobileFileName, selectedDesktopFileName } = this.state
    const bannerIndex = sectionIndex
    const fileName = isBannerType === "mobile" ? selectedMobileFileName : selectedDesktopFileName
    let updatedImageVariants = this.state.imageVariants
    updatedImageVariants[bannerIndex] = imageVariant
    if (data) {
      this.setState(
        {
          selectedCropedFile: data,
          image: data,
          imageVariants: updatedImageVariants,
          imageVariantsIndex:bannerIndex
        },
        () => {
          this.props.updateBannerMobileData(bannerIndex, data, fileName, isBannerType);
          this.setState({
            cropperSectionOpen: false,
            uploadMobileBannerOpen: false
          }, () => {
            this.openToastChange()
          });
        }
      );
    } 
  };

  convertBase64ToImageFile = (baseFile: any) => {
    const base64ImageData = baseFile;
    const [contentType, base64Data] = base64ImageData.split(';base64,');
    const mimeType = contentType.split(':')[1];
    const binaryData = window.atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const file = new File([blob], "image.png", { type: mimeType });
    return file

  }

  closeDeleteSectionModal = () => {
    this.setState({ deleteSectionModal: false })
  }

  deleteSection = () => {
    const { sectionData, isDeleteSection, sectionMainData, deleteSectionItemIndex } = this.state;

    const updatedSectionMainData = sectionMainData.map((item) => {
      if (item.sectionId === isDeleteSection.sectionId) {
        return {
          ...item,
          is_Deleted: true
        };
      }
      return item;
    });

    const updatedSectionData = [...sectionData];
    updatedSectionData.splice(deleteSectionItemIndex, 1);

    this.setState({
      sectionData: updatedSectionData,
      sectionMainData: updatedSectionMainData,
      deleteSectionItemIndex: -1,
      deleteSectionModal: false,
    }, () => {
      this.props.deleteSection(deleteSectionItemIndex)
      this.saveChangesConditions()
    });

    this.setState({
      sectionMainData: updatedSectionMainData
    });
  }


  openDeleteSectionModal = (sectionItemIndex: number, sectionDelete: { [key: string]: string | boolean }) => {
    this.setState({
      deleteSectionItemIndex: sectionItemIndex,
      deleteSectionModal: true,
      isDeleteSection: sectionDelete
    })
  }

  handleChangeFacebook = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const facebookUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;

    this.setState({
      facebookUrl: trimmedValue,
    }, () => {
      if (this.state.facebookUrl === this.state.facebookTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (facebookUrlRegex.test(this.state.facebookUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorStatus: false,
          checkStatus: true,
          isFacebookError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.facebookUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorStatus: false,
            checkStatus: false,
            isFacebookError: false
          })
        } else {
          this.setState({
            errorStatus: true,
            checkStatus: false,
            isFacebookError: false
          })
        }
      }
    })
  }
  
  handleChangeInstagram = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const instagramUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      instagramUrl: trimmedValue,
    }, () => {
      if (this.state.instagramUrl === this.state.instagramTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (instagramUrlRegex.test(this.state.instagramUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorInstagramStatus: false,
          checkInstagramStatus: true,
          isInstagramError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.instagramUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorInstagramStatus: false,
            checkInstagramStatus: false,
            isInstagramError: false
          })
        } else {
          this.setState({
            errorInstagramStatus: true,
            checkInstagramStatus: false,
            isInstagramError: false
          })
        }
      }
    })
  };

  handleChangeTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const twitterUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      twitterUrl: trimmedValue,
    }, () => {
      if (this.state.twitterUrl === this.state.twitterTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (twitterUrlRegex.test(this.state.twitterUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorTwitterStatus: false,
          checkTwitterStatus: true,
          isTwitterError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.twitterUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorTwitterStatus: false,
            checkTwitterStatus: false,
            isTwitterError: false
          })
        } else {
          this.setState({
            errorTwitterStatus: true,
            checkTwitterStatus: false,
            isTwitterError: false
          })
        }
      }
    })
  };

  handleChangeYouTube = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const youTubeUrlRegex = /^(www\.|http:\/\/|https:\/\/)/;
    this.setState({
      youTubeUrl: trimmedValue,
    }, () => {
      if (this.state.youTubeUrl === this.state.youTubeTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (youTubeUrlRegex.test(this.state.youTubeUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorYouTubeStatus: false,
          checkYouTubeStatus: true,
          isYoutubeError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.youTubeUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorYouTubeStatus: false,
            checkYouTubeStatus: false,
            isYoutubeError: false
          })
        } else {
          this.setState({
            errorYouTubeStatus: true,
            checkYouTubeStatus: false,
            isYoutubeError: false
          })
        }
      }
    })
  }
  
  handleChangePlayStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const playStoreUrlRegex = /^https?:\/\/(www\.)?play\.google\.com\/store\/apps\//;
    this.setState({
      playStoreUrl: trimmedValue,
    }, () => {
      if (this.state.playStoreUrl === this.state.playStoreTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (playStoreUrlRegex.test(this.state.playStoreUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorPlayStoreStatus: false,
          checkPlayStoreStatus: true,
          isPlayError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.playStoreUrl) {
          this.props.updateFooter(name, value)
          this.setState({
            errorPlayStoreStatus: false,
            checkPlayStoreStatus: false,
            isPlayError: false
          })
        } else {
          this.setState({
            errorPlayStoreStatus: true,
            checkPlayStoreStatus: false,
            isPlayError: false
          })
        }
      }
    })
  }

  handleChangeAppStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const trimmedValue = value.trim();
    const appStoreUrlRegex = /^https?:\/\/(www\.)?apps\.apple\.com/;

    this.setState({
      appStoreUrl: trimmedValue,
    }, () => {
      if (this.state.appStoreUrl === this.state.appStoreTempUrl) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
      if (appStoreUrlRegex.test(this.state.appStoreUrl)) {
        this.props.updateFooter(name, value)
        this.setState({
          errorAppStoreStatus: false,
          checkAppStoreStatus: true,
          isAppError: false
        }, () => {
          this.checkEmptyValidations()
        })
      } else {
        if (!this.state.appStoreUrl) {
          this.props.updateFooter(name, value)

          this.setState({
            errorAppStoreStatus: false,
            checkAppStoreStatus: false,
            isAppError: false
          })
        } else {
          this.setState({
            errorAppStoreStatus: true,
            checkAppStoreStatus: false,
            isAppError: false
          })
        }
      }
    })
  };

  async getStoreDetails() {
    const getStoreDetails = await generateRequestMessage(
      configJSON.getStoreDetailAPI,
      configJSON.getApiMethod
    );
    this.getStoreDetailsApiCallId = getStoreDetails.messageId;
    runEngine.sendMessage(getStoreDetails.id, getStoreDetails);
  }

  handleStoreDetailsResponse(responseJson: any) {
    const storeName = responseJson?.data?.attributes?.name || "Studio Store";
    const currentYear = moment().format("YYYY");
    if (responseJson?.data?.attributes?.name) {
      this.setState({
        copyRight: `Copyright © ${currentYear} ${storeName}. All rights reserved`,
      });
    }
  }

  handleChangeCopyRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    this.setState({
      copyRight: value,
    }, () => {
      this.props.updateFooter(name, value)
      if (this.state.copyRight.length > 150) {
        this.setState({
          copyRightError: "Copyright must be at maximum of 150 characters"
        })
      }
      if (this.state.copyRight === this.state.copyTempRight) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    })
  };

  closeSelectedModal = () => {
    this.setState({
      selectedModal: false, selectedImage: {
        type: "",
        name: "",
        url: "",
        isSelect: false,
        category: "",
        sub_category: "",
      },
    });
    this.getImageCatalogueAPI = this.apiCallHandle(this.state.token, `${configJSON.getImageCatalougeAPIEndpoint}?per_page=999&search=`, configJSON.getApiMethod)
  };

  selectActiveTab = (tabName: string) => {
    this.setState({ activeModalTabName: tabName });
  };

  selectImage = (imageIndex: number, name: string) => {
    const imageArray = [...this.state.modalImages];
    imageArray.forEach((imageItem, index) => {
      if (index == imageIndex) {
        imageItem = {
          ...imageItem,
          isSelect: true,
        }
      } else {
        imageItem = {
          ...imageItem,
          isSelect: false,
        }
      }
      imageArray[index] = imageItem;
      if (this.state.isBannerType === "mobile") {
        this.setState({
          selectedMobileFileName: name,
          bannerimageSelected : "bannerLibrary"
        })
      } else {
        this.setState({
          selectedDesktopFileName: name,
          bannerimageSelected : "bannerLibrary"
        })
      }
      this.setState({
        selectedImage: imageArray[imageIndex],
        modalImages: imageArray
      }, () => {
        this.imageUrlToBase64(this.state.selectedImage);
      })
    })
  };

  imageUrlToBase64 = (data: ModalImageData) => {
    if (data.url) {
      const secureUrl = data.url.replace(/^http:\/\//i, 'https://');
      const imgData = new Image();

      imgData.crossOrigin = 'Anonymous';

  imgData.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = imgData.width;
    canvas.height = imgData.height;

    context?.drawImage(imgData, 0, 0, imgData.width, imgData.height);

    const base64Data = canvas.toDataURL('image/jpeg'); 
    this.setState({
      selectedFile: base64Data,
      selectedFileType: 'image/jpeg', 
    });
  };

  imgData.src = secureUrl;
}
};

  toggleAppStoreLinkInputBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name } = event.target;
    this.setState({
      showStoreLinkInputBox: event.target.checked,
    }, () => {
      this.props.realTimeHandler(this.state.showStoreLinkInputBox, name, "footer", "")
      if (this.state.showStoreLinkInputBox === this.state.appTempStore) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleContactInformation = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, checked } = event.target
    this.setState({
      showContactInformation: checked,
    }, () => {
      this.props.updateFooter(name, checked)
      if (this.state.showContactInformation === this.state.contactTempInformation) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleStorenameForHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trigger = event.target.checked
    this.setState({
      showStoreName: trigger,
    }, () => {
      if (this.state.showStoreName === this.state.isStoreNameOpenChecker) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
    this.props.HandleStoreNameEnabled(trigger)
  };

  toggleMobileMenuForHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trigger = event.target.checked
    this.setState({
      isMobileMenuOpen: trigger,
    }, () => {
      if (this.state.isMobileMenuOpen === this.state.isMobileMenuOpenChecker) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
    this.props.HandleMobileMenyTrigger(trigger)
  };

  closeToastChange = () => {
    //this.props.hideHeaderBar()
  };

  selectImageFile = (files: any) => {
    this.setState({
      uploadMobileBannerOpen: false
    })
    const reader = new FileReader();
    reader.onload = () => {
      if (this.state.isBannerType === "mobile") {
        this.setState({
          selectedMobileFileName: files[0].name,
          bannerimageSelected : "custom"
        })
      } else {
        this.setState({
          selectedDesktopFileName: files[0].name,
          bannerimageSelected : "custom"
        })
      }
      this.setState({ selectedFile: reader.result, sectionImageSize: files[0].size, selectedFileType: files[0].type }, () => {
        this.handleSectionCropperOpen();
        // this.openToastChange()
      });
    };
    reader.readAsDataURL(files[0]);
  };


  handleNextCatalogue = () => {
    if (this.state.selectedImage.isSelect === true) {
      this.setState({
        cropperSectionOpen: true
      }, () => {
        this.setState({
          selectedModal: false
        })
      })
    }
  };

  handleUploadModal = (selectedTable: string, curIndex: number, sectionId: string) => {
    this.setState({
      uploadMobileBannerOpen: true,
      isBannerType: selectedTable,
      sectionId,
      sectionIndex: curIndex,
    }, () => {
      // this.openToastChange()
    })
  };

  handleSectionCropperOpen = () => {
    this.setState({
      cropperSectionOpen: true
    }, () => {
      this.setState({
        uploadMobileBannerOpen: false
      })
    })
  };

  getLogoFaviconModalTitle = () => {
    if(this.state.uploadModalType === "logo"){
      if(this.state.isLogoUploaded){
        return configJSON.editLogo
      }else{
        return configJSON.addLogoText
      }
    }else if(this.state.uploadModalType === "favicon"){
      if(!this.state.isCustomFaviconSelected){
        return configJSON.editFavicon
      }else{
        return configJSON.addFaviconText
      }
    }

    return "";
  };

  openTextLogoModal = ()=>{
    this.setState({
      HeaderCropSectionOpen: false,
      isHeaderImageUploaded: false
    }, () => {
      this.setState({
        isTextLogoModal: true
      })
    })
  };

  removeUploadedFavicon = () => {
    this.setState({
      isCustomFaviconSelected:false,
      selectedFavicon:null,
      SelectedFaviconFileSize:0,
      SelectedFaviconFileType:"",
      SelectedFaviconName:"",
    })
  };

  saveDefaultFavicon = () => {
    this.setState({
      isFaviconUploaded: true,
      selectedFavicon: null,
      isCustomFaviconSelected:false,
      HeaderCropSectionOpen: false,
      uploadHeaderLogoModalOpen: false,
      isFaviconEdited:true,
      prevSelectedFavicon:{
        name:"",
        type:"",
        size:0,
        favicon:""
      }
    }, () => {
      this.openToastChange();
    })
  };

  HandleLogoUploadModalOpen = (modalType: string) => {
    if (this.state.isLogoUploaded && modalType === "logo" || this.state.isFaviconUploaded && modalType === "favicon") {
      this.setState({
        HeaderCropSectionOpen: true,
        uploadModalType: modalType,
        isHeaderImageUploaded: false
      })
      if (this.state.isLogoUploaded && modalType == "logo") {
        this.setState({ ratio: 4.2 })
      }
      if (this.state.isFaviconUploaded && modalType === "favicon") {
        this.setState({ ratio: 1.1 })
      }
      return
    }
    if(!this.state.isLogoUploaded && modalType === "logo") {
      this.setState({ isTextLogoModal:true, uploadModalType: modalType })
      return;
    }
    this.setState({
      uploadHeaderLogoModalOpen: true,
      uploadModalType: modalType,
      isHeaderImageUploaded: false
    })
  };

  HandleLogoUploadModalClose = () => {
    this.setState({
      uploadHeaderLogoModalOpen: false,
      HeaderCropSectionOpen: false,
      isTextLogoModal: false
    }, () => {
      if(this.state.uploadModalType === "favicon" && this.state.isFaviconEdited){
        this.setState({
          selectedFavicon:this.state.prevSelectedFavicon.favicon,
          SelectedFaviconFileSize:this.state.prevSelectedFavicon.size,
          SelectedFaviconFileType:this.state.prevSelectedFavicon.type,
          SelectedFaviconName:this.state.prevSelectedFavicon.name,
          isCustomFaviconSelected: this.state.prevSelectedFavicon.favicon !== ""
        }, () => {
          this.openToastChange();
        })
        
      }else{
        this.getProductPreview();
      }
    })
  };

  HandleNextForHeaderModal = () => {
    this.setState({
      HeaderCropSectionOpen: true
    }, () => {
      this.setState({
        uploadHeaderLogoModalOpen: false
      })
    })
  };

  selectLogoFile = (files: Blob[] & FileList) => {
    this.setState({
      uploadMobileBannerOpen: false,
      isHeaderImageUploaded: true
    })
    const reader = new FileReader();
    reader.onload = () => {
      if (this.state.uploadModalType === "logo") {
        this.setState({ selectedLogo: reader.result, SelectedLogoFileSize: files[0].size, SelectedLogoFileType: files[0].type, SelectedLogoName: files[0].name }, () => {
          this.HandleNextForHeaderModal();
        });
      } else {
        this.setState({ selectedFavicon: reader.result, SelectedFaviconFileSize: files[0].size, SelectedFaviconFileType: files[0].type, SelectedFaviconName: files[0].name,  isCustomFaviconSelected:true }, () => {
          this.HandleNextForHeaderModal();
        });
      }
    };
    reader.readAsDataURL(files[0]);
  };

  handleEditIconClick = (index: number) => {
    if (this.inputRef) {
      this.inputRef.focus();

      this.setState({ focusedIndex: index })
    }
  }

  handleBlur = () => {
    this.setState({
      focusedIndex: null,
    });
  };

  handleLogoFaviconCropper = (data: string) => {
    if (data) {
      if (this.state.uploadModalType === "logo") {
        this.setState({
          isLogoUploaded: true,
          selectedLogo: data,
          removeLogo:false,
          showStoreName: true
        }, () => {
          this.openToastChange()
        })
        this.props.HandleLogoUpload(data)
      }
      if (this.state.uploadModalType === "favicon") {
        this.setState({
          isFaviconUploaded: true,
          selectedFavicon: data,
          prevSelectedFavicon:{
            name:this.state.SelectedFaviconName ?? "",
            type:this.state.SelectedFaviconFileType,
            size:this.state.SelectedFaviconFileSize,
            favicon:data
          },
          isFaviconEdited:true,
          isCustomFaviconSelected:true
        }, () => {
          this.openToastChange()
        })
      }
      this.setState({
        HeaderCropSectionOpen: false,
        uploadHeaderLogoModalOpen: false
      });
    }
  };

  HandleNextForHeaderModalClose = () => {
    this.setState({
      HeaderCropSectionOpen: false,
      isHeaderImageUploaded: false,
      isTextLogoModal:false
    }, () => {
      this.setState({
        uploadHeaderLogoModalOpen: true,
      })
    })
  };

  openToastChange = () => {
    this.props.showHeaderBar({
      message: "",
      ignoreClickAway: true,
    });
  };

  toggleSocialLinksBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    this.setState({
      showSocialLinks: checked,
    }, () => {
      this.props.updateFooter(name, checked)
      if (this.state.showSocialLinks === this.state.socialTempMedia) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    });
  };

  toggleFooterContent = () => {
    this.setState({
      footerContent: !this.state.footerContent,
      sectionContent: false,
      headerContent: false,
      themeFontsContent: false,
    }, () => {
      if (this.state.footerContent) {
        this.updateExpandedSection('footer');
      } else {
        this.updateExpandedSection('blank');
      }

    });
    this.updateSectionState('footer');
    this.props.handleCloseAllSections()
  };

  toggleHeaderContent = () => {
    this.setState({
      headerContent: !this.state.headerContent,
      sectionContent: false,
      footerContent: false,
      themeFontsContent: false,
    }, () => {
      if (this.state.headerContent) {
        this.updateExpandedSection('header');
      } else {
        this.updateExpandedSection('blank');

      }

    });
    this.updateSectionState('header');
    this.props.handleCloseAllSections()
  }

  toggleThemeFontsContent = () => {
    this.setState({
      themeFontsContent: !this.state.themeFontsContent,
      headerContent: false,
      sectionContent: false,
      footerContent: false,
    }, () => {
      if (this.state.themeFontsContent) {
        this.updateExpandedSection('themeFonts');
      } else {
        this.updateExpandedSection('blank');
      }
    });
    this.updateSectionState('themeFonts');
    this.props.handleCloseAllSections()
  }

  handleAPIMessageShowContentUpdateState = (responseJson:any,metadata:any)=>{
    this.setState({
      showProductPreview: {
        themes_and_font: responseJson.data.attributes?.themes_and_font.data?.attributes,
        footer: responseJson.data.attributes?.footer.data?.attributes,
        sections: responseJson.data.attributes?.sections,
        header: responseJson.data.attributes?.header?.data?.attributes,
        metadata: isJson(metadata) ? JSON.parse(metadata) : metadata
      }, sectionDataN: responseJson.data?.attributes.sections?.data,
    }, () => {
      this.props.createIdHandler(responseJson.data.id, this.state.showProductPreview)
      
    this.updateStateData()
    
    })
  }

  headerNavigationUpdateData=(HeaderNavigationData:HeaderNavigation)=>{
    const { metadata, is_mobile_menu, show_business_name, logo_filename, favicon_filename, template_details } = HeaderNavigationData.data.attributes;
    this.setState({
      navigationItems: isJson(metadata) ? JSON.parse(metadata) : metadata,
      manageToster: isJson(metadata) ? JSON.parse(metadata) : metadata,
      isMobileMenuOpen: is_mobile_menu,
      isMobileMenuOpenChecker: is_mobile_menu,
      showStoreName: show_business_name,
      isStoreNameOpenChecker: show_business_name,
      SelectedLogoName: logo_filename,
      isLogoUploaded: logo_filename !== "",
      isFaviconUploaded: favicon_filename !== "",
      headerSelected: template_details?.name
    });
  }

  handleAPIMessageShowContent = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.getProductPreviewContentCallId === apiRequestCallId) {
      
      if (responseJson && responseJson.data) {
        this.props.hideLoader()
        const metadatas = responseJson.data.attributes?.metadata;
        const HeaderNavigationData = responseJson?.data?.attributes?.header;
        const handleThemeFonts = responseJson?.data?.attributes?.themes_and_font?.data?.attributes;
        this.headerNavigationUpdateData(HeaderNavigationData)
        this.handleAPIMessageShowContentUpdateState(responseJson,metadatas)
        const footerNavigationData = responseJson?.data?.attributes?.footer;
        this.props.updateFooterCopyRight(footerNavigationData.data.attributes.copyright)
        this.setState({
          section: responseJson?.data?.attributes?.sections?.data,
          showContactInformation:footerNavigationData.data.attributes.is_contact,
          contactTempInformation:footerNavigationData.data.attributes.is_contact,
          showSocialLinks:footerNavigationData.data.attributes.is_social_media,
          socialTempMedia:footerNavigationData.data.attributes.is_social_media,
          showStoreLinkInputBox:footerNavigationData.data.attributes.is_app_link,
          appTempStore:footerNavigationData.data.attributes.is_app_link,
          facebookUrl:this.state.showProductPreview?.footer?.facebook_url === null ? "" : this.state.showProductPreview?.footer?.facebook_url,
          facebookTempUrl:footerNavigationData.data.attributes.facebook_url,
          instagramUrl:footerNavigationData.data.attributes.instagram_url  === null ? "" : this.state.showProductPreview?.footer?.instagram_url,
          instagramTempUrl:footerNavigationData.data.attributes.instagram_url,
          twitterUrl:footerNavigationData.data.attributes.x_url  === null ? "" : this.state.showProductPreview?.footer?.x_url,
          twitterTempUrl:footerNavigationData.data.attributes.x_url,
          youTubeUrl:footerNavigationData.data.attributes.youtube_url  === null ? "" : this.state.showProductPreview?.footer?.youtube_url,
          youTubeTempUrl:footerNavigationData.data.attributes.youtube_url,
          playStoreUrl:footerNavigationData.data.attributes.playstore_url  === null ? "" : this.state.showProductPreview?.footer?.playstore_url,
          playStoreTempUrl:footerNavigationData.data.attributes.playstore_url,
          appStoreUrl:footerNavigationData.data.attributes.appstore_url  === null ? "" : this.state.showProductPreview?.footer?.appstore_url,
          appStoreTempUrl:footerNavigationData.data.attributes.appstore_url,
          // copyRight:footerNavigationData.data.attributes.copyright, 
          copyTempRight:footerNavigationData.data.attributes.copyright
        });

        this.setState((prevState) => ({
          selectedColor: {
            ...prevState.selectedColor,
            primary: handleThemeFonts.primary_colour,
            secondary: handleThemeFonts.secondary_colour
          },
        }));

          let testimonialData = responseJson.data.attributes.sections.data.find((item: { attributes: { section_type: string; }; }) => item.attributes.section_type == "testimonial")
          const {metadata} = testimonialData.attributes;
          const isTestimonialCheck = isJson(metadata) ? JSON.parse(metadata).isTestimonial : metadata.isTestimonial;

          let LocationData = responseJson.data.attributes.sections.data.find((item: { attributes: { section_type: string; }; }) => item.attributes.section_type == "location")
          const metaCheck = LocationData.attributes.metadata;
          const isLocationCheck = isJson(metadata) ? JSON.parse(metadata).isLocation : metadata.isLocation;

          this.setState({
            showTestimonial: isTestimonialCheck,
            showLocation: isLocationCheck
          });
      } else {
        this.props.hideLoader()
        this.updateFooterCopyright()
      }
    }
  };
updateStateData(){
  const selectedPrimaryColor = this.state.showProductPreview?.themes_and_font?.primary_colour ?? "";
  const selectedSecondaryColor = this.state.showProductPreview?.themes_and_font?.secondary_colour ?? "";
  const isDefaultColor = this.state.defaultColors.find((color: any) => 
    color.primary === selectedPrimaryColor && color.secondary === selectedSecondaryColor);
  this.props.updateFooterCopyRight(this.state.showProductPreview?.footer?.copyright)
  this.setState({
    selectedColor: { 
      primary: selectedPrimaryColor,
      secondary: selectedSecondaryColor,
      id: isDefaultColor ? isDefaultColor.id : 0,
    },
    selectedFonts: {
      headerText: this.state.showProductPreview?.themes_and_font?.header_text ?? "",
      bodyText: this.state.showProductPreview?.themes_and_font?.body_text ?? "",
    },
    selectedMobileFileName: this.state.showProductPreview?.sections?.data[0]?.attributes?.mobile_banner_filename,
    showContactInformation: this.state.showProductPreview?.footer?.is_contact,
    showStoreLinkInputBox: this.state.showProductPreview?.footer?.is_app_link,
    showSocialLinks: this.state.showProductPreview?.footer?.is_social_media,
    facebookUrl: this.state.showProductPreview?.footer?.facebook_url === null ? "" : this.state.showProductPreview?.footer?.facebook_url,
    instagramUrl: this.state.showProductPreview?.footer?.instagram_url === null ? "" : this.state.showProductPreview?.footer?.instagram_url,
    twitterUrl: this.state.showProductPreview?.footer?.x_url === null ? "" : this.state.showProductPreview?.footer?.x_url,
    youTubeUrl: this.state.showProductPreview?.footer?.youtube_url === null ? "" : this.state.showProductPreview?.footer?.youtube_url,
    linkedinUrl: this.state.showProductPreview?.footer?.linkedin_url === null ? "" : this.state.showProductPreview?.footer?.linkedin_url,
    playStoreUrl: this.state.showProductPreview?.footer?.playstore_url === null ? "" : this.state.showProductPreview?.footer?.playstore_url,
    appStoreUrl: this.state.showProductPreview?.footer?.appstore_url === null ? "" : this.state.showProductPreview?.footer?.appstore_url,
    // copyRight: this.state.showProductPreview?.footer?.copyright,
    contactTempInformation: this.state.showProductPreview?.footer?.is_contact,
    appTempStore: this.state.showProductPreview?.footer?.is_app_link,
    socialTempMedia: this.state.showProductPreview?.footer?.is_social_media,
    facebookTempUrl: this.state.showProductPreview?.footer?.facebook_url,
    instagramTempUrl: this.state.showProductPreview?.footer?.instagram_url,
    twitterTempUrl: this.state.showProductPreview?.footer?.x_url,
    youTubeTempUrl: this.state.showProductPreview?.footer?.youtube_url,
    linkedinTempUrl: this.state.showProductPreview?.footer?.linkedin_url,
    playStoreTempUrl: this.state.showProductPreview?.footer?.playstore_url,
    appStoreTempUrl: this.state.showProductPreview?.footer?.appstore_url,
    copyTempRight: this.state.showProductPreview?.footer?.copyright,
    isMobileMenuOpen: this.state.showProductPreview?.header?.is_mobile_menu === null ? false : this.state.showProductPreview?.header?.is_mobile_menu,
    showStoreName: this.state.showProductPreview?.header?.show_business_name,
    isMobileMenuOpenChecker: this.state.showProductPreview?.header?.is_mobile_menu,
    showStoreNameChecker: this.state.showProductPreview?.header?.show_business_name,
    allSelectValue: this.state.showProductPreview?.metadata?.selectedValue,
    isLogoUploaded: this.state.showProductPreview?.header?.logo_url?.length ? true : false,
    isFaviconUploaded: this.state.showProductPreview?.header?.favicon_url?.length ? true : false,
    selectedLogo: this.state.showProductPreview?.header?.logo_url,
    selectedFavicon: this.state.showProductPreview?.header?.favicon_url,
    SelectedLogoName: this.state.showProductPreview?.header?.logo_filename === undefined ? "" : this.state.showProductPreview?.header?.logo_filename,
    SelectedFaviconName: this.state.showProductPreview?.header?.favicon_filename === undefined ? "" : this.state.showProductPreview?.header?.favicon_filename,
    isCustomFaviconSelected: this.state.showProductPreview?.header?.custom_favicon ?? false,
  })
}
  resetAllErrors = () => {
    this.setState({
      checkPlayStoreStatus: false,
      checkInstagramStatus: false,
      checkTwitterStatus: false,
      checkYouTubeStatus: false,
      checkLinkedinStatus: false,
      checkAppStoreStatus: false,
      checkStatus: false,
      isPlayError: false,
      isAppError: false,
      isYoutubeError: false,
      isFacebookError: false,
      isInstagramError: false,
      isTwitterError: false,
      isLinkedinError: false,
    })
  };

  toggleSectionContent = () => {
    this.setState({
      sectionContent: !this.state.sectionContent,
      footerContent: false,
      headerContent: false,
      themeFontsContent: false
    }, () => {
      if (this.state.sectionContent) {
        this.updateExpandedSection('section');
      } else {
        this.updateExpandedSection('blank');
      }

    });
    this.updateSectionState('section');
    this.props.handleCloseAllSections()
  };

  handleAPIMessageCreateContent = async (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.postCreateWebsiteBuilderCallId === apiRequestCallId) {
      if (responseJson) {
        this.props.createIdHandler(responseJson.data.id, responseJson)
        this.props.apiCalledHandler()
        this.props.handleGetAPITrigger()
        await setStorageData("id", responseJson.data.id);
        this.getProductPreview()
        this.props.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
        this.resetAllErrors()
      }
      this.props.hideLoader()
    }
  };

  handleAPIMessageUpdateContent = async (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (this.putWebsiteBuilderCallId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.props.createIdHandler(responseJson.data.id, responseJson);
        this.props.apiCalledHandler()
        this.props.handleGetAPITrigger()
        await setStorageData("id", responseJson.data.id);
        this.getProductPreview()
        this.resetAllErrors()
        this.props.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
        this.trackMixPanel()
      }
      this.props.hideLoader()
    }
  };

  trackMixPanel = () => {
    mixpanel.track(`${this.state.buildCardID}_webadmin_primary_color`,{color: this.state.selectedColor.primary, buildCardID: this.state.buildCardID});
    mixpanel.track(`${this.state.buildCardID}_webadmin_secondary_color`,{color: this.state.selectedColor.secondary, buildCardID: this.state.buildCardID});
    mixpanel.track(`${this.state.buildCardID}_webadmin_header_text_font`,{color: this.state.selectedFonts.headerText, buildCardID: this.state.buildCardID});
    mixpanel.track(`${this.state.buildCardID}_webadmin_body_text_font`,{color: this.state.selectedFonts.bodyText, buildCardID: this.state.buildCardID});

    if(this.state.textPlatform === "mobile"){
      mixpanel.track(`${this.state.buildCardID}_webadmin_mobile_banner_text`,{bannerText: this.state.bannerTextProperties["mobile"].headerText, buildCardID: this.state.buildCardID});
    }else{
      mixpanel.track(`${this.state.buildCardID}_webadmin_desktop_banner_text`,{bannerText: this.state.bannerTextProperties["desktop"].headerText, buildCardID: this.state.buildCardID});
    }

    if(this.state.bannerimageSelected === "bannerLibrary") {
      this.state.isBannerType === "mobile" ?
      mixpanel.track(`${this.state.buildCardID}_webadmin_uploaded_banner_library_image`,{uploadImg: this.state.selectedMobileFileName, buildCardID: this.state.buildCardID})
      :
      mixpanel.track(`${this.state.buildCardID}_webadmin_uploaded_banner_library_image`,{uploadImg: this.state.selectedDesktopFileName, buildCardID: this.state.buildCardID})
    }else{
      this.state.isBannerType === "mobile" ?
      mixpanel.track(`${this.state.buildCardID}_webadmin_uploaded_custom_banner_image`,{uploadImg: this.state.selectedMobileFileName, buildCardID: this.state.buildCardID})
      :
      mixpanel.track(`${this.state.buildCardID}_webadmin_uploaded_custom_banner_image`,{uploadImg: this.state.selectedDesktopFileName, buildCardID: this.state.buildCardID});
    }
  };

  getSectionDataId = (section: string | boolean | ArrayBuffer | null) => {
    let test: string = ""
    this.state.sectionDataN.filter((item) => {
      return item.attributes.section_type === section;
    }).forEach((item, index) => {
      test = item.id;
    });
    return test
  }

  handleImageSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    const searchValue = event.target.value;

    this.debounceTimeout = setTimeout(() => {
      this.setState({ isLoading: true })
      this.getImageCatalogueAPI = this.apiCallHandle(this.state.token, `${configJSON.getImageCatalougeAPIEndpoint}filter_by=${this.state.selectImageType}&keyword=${searchValue}`, configJSON.getApiMethod)
    }, 300);
  };

  handleImageCatalogResponse = (responseJson: ApiResponse) => {
    const newImages: ModalImage[] = responseJson.data.map((image) => ({
      type: image.type ,
      name: image.attributes.name ,
      url: image.attributes.url ,
      category: image.attributes?.category ?? "" ,
      sub_category : image.attributes?.sub_category ?? "" ,
      isSelect: false
    }));

    this.setState({
      modalImages: newImages,
      isLoading: false
    });
  }

  checkLogoType = () => {
    const { uploadModalType, SelectedLogoName, SelectedFaviconName } = this.state
    if (uploadModalType === "logo") {
      if (SelectedLogoName && SelectedLogoName.trim() !== "") {
        return "Edit Logo";
      } else {
        return "Add Logo";
      }
    } else if (uploadModalType === "favicon") {
      if (SelectedFaviconName && SelectedFaviconName.trim() !== "") {
        return "Edit Favicon";
      } else {
        return "Add Favicon";
      }
    }
  }

  createWebsiteBuilderContent = async () => {
    this.props.showLoader();
    let formData = new FormData();
    const requestMessage = await generateRequestMessage(
      configJSON.createContentWebsiteBuilderEndPoint,
      configJSON.postAPiMethod,
      { "content-type": undefined }
    );

    if (this.props.selectedTab)
      formData.append("designs[design_platform]", this.props.selectedTab);
    formData.append("designs[footer_attributes][is_social_media]", this.state.showSocialLinks.toString());
    formData.append("designs[footer_attributes][facebook_url]", this.state.facebookUrl);
    formData.append("designs[footer_attributes][youtube_url]", this.state.youTubeUrl);
    formData.append("designs[footer_attributes][is_contact]", this.state.showContactInformation.toString());
    formData.append("designs[footer_attributes][is_app_link]", this.state.showStoreLinkInputBox.toString());
    formData.append("designs[footer_attributes][copyright]", this.props.footerCopyRight);
    formData.append("designs[footer_attributes][playstore_url]", this.state.playStoreUrl);
    formData.append("designs[footer_attributes][appstore_url]", this.state.appStoreUrl);
    formData.append("designs[footer_attributes][linkedin_url]", this.state.linkedinUrl);
    formData.append("designs[footer_attributes][x_url]", this.state.twitterUrl);
    formData.append("designs[footer_attributes][instagram_url]", this.state.instagramUrl);

   this.checkValidInputForForfaviconAndIconForUpdate(formData)
    formData.append("designs[header_attributes][show_business_name]", this.state.showStoreName?.toString());
    formData.append("designs[header_attributes][is_mobile_menu]", this.state.isMobileMenuOpen?.toString());
    formData.append("designs[header_attributes][logo_filename]", this.state.SelectedLogoName === null ? "" : this.state.SelectedLogoName.toString());
    formData.append("designs[header_attributes][favicon_filename]", this.state.SelectedFaviconName === null ? "" : this.state.SelectedFaviconName.toString());
 
    formData.append("designs[header_attributes][custom_favicon]", this.state.isCustomFaviconSelected ? 'true' : 'false');
    formData.append("designs[header_attributes][template_id]", this.state.templeteId );

   this.manageformDataForUpdateData(formData)

    this.postCreateWebsiteBuilderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductPreview = async () => {
    this.props.showLoader();
    await this.getDefaultColors()
    this.getProductPreviewContentCallId = this.apiCallHandle(this.state.token, configJSON.createContentWebsiteBuilderEndPoint, configJSON.getApiMethod);
  };

  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = { token: token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), header);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), body)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
updateFormData = (formData:any)=>{
  
}
  updateWebsiteBuilderContent = async () => {
    let id = "6"
    this.props.showLoader();
    let formData = new FormData();
    const requestMessage = await generateRequestMessage(
      `${configJSON.createContentWebsiteBuilderEndPoint}/${id}`,
      configJSON.putAPiMethod,
      { "content-type": undefined }
    );
  
    if (this.props.selectedTab)
      formData.append("designs[design_platform]", this.props.selectedTab);
    if (id)
      formData.append("designs[footer_attributes][id]", id)
    formData.append("designs[themes_and_font_attributes][primary_colour]", this.state.selectedColor.primary);
    formData.append("designs[themes_and_font_attributes][secondary_colour]", this.state.selectedColor.secondary);
    formData.append("designs[themes_and_font_attributes][header_text]", this.state.selectedFonts.headerText);
    formData.append("designs[themes_and_font_attributes][body_text]", this.state.selectedFonts.bodyText);
    formData.append("designs[footer_attributes][is_social_media]", this.state.showSocialLinks.toString());
    formData.append("designs[footer_attributes][facebook_url]", this.state.facebookUrl);
    formData.append("designs[footer_attributes][youtube_url]", this.state.youTubeUrl);
    formData.append("designs[footer_attributes][is_contact]", this.state.showContactInformation.toString());
    formData.append("designs[footer_attributes][is_app_link]", this.state.showStoreLinkInputBox.toString());
    formData.append("designs[footer_attributes][copyright]", this.props.footerCopyRight);
    formData.append("designs[footer_attributes][playstore_url]", this.state.playStoreUrl);
    formData.append("designs[footer_attributes][appstore_url]", this.state.appStoreUrl);
    formData.append("designs[footer_attributes][linkedin_url]", this.state.linkedinUrl);
    formData.append("designs[footer_attributes][x_url]", this.state.twitterUrl);
    formData.append("designs[footer_attributes][instagram_url]", this.state.instagramUrl);
    
this.checkValidInputForForfaviconAndIconForUpdate(formData);
   
    formData.append("designs[header_attributes][show_business_name]", this.state.showStoreName?.toString());
    formData.append("designs[header_attributes][is_mobile_menu]", this.state.isMobileMenuOpen?.toString() === null ? "" : this.state.isMobileMenuOpen?.toString());
    formData.append("designs[header_attributes][logo_font]", this.props.parentStates.textLogoMetaData.fontType);
    formData.append("designs[header_attributes][logo_colour]", this.props.parentStates.textLogoMetaData.colour);
    formData.append("designs[header_attributes][logo_filename]", (this.state.SelectedLogoName === null || undefined) ? "" : this.state.SelectedLogoName.toString());
    formData.append("designs[header_attributes][favicon_filename]", this.state.SelectedFaviconName === null ? "" : this.state.SelectedFaviconName.toString());
    formData.append("designs[header_attributes][metadata]", this.state.navigationItems === null ? "" : JSON.stringify(this.state.navigationItems));
    formData.append("designs[header_attributes][custom_favicon]", this.state.isCustomFaviconSelected ? "true" : "false");
    formData.append("designs[header_attributes][remove_logo]", this.props.parentStates.removeLogo.toString());
    formData.append("designs[header_attributes][template_id]", this.state.templeteId );
    this.manageformDataForUpdateData(formData);
   

    this.putWebsiteBuilderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkValidInputForForfaviconAndIconForUpdate=(formData:any)=>{
    const isValidInput = (input: string | null | undefined): boolean => {
      return input !== undefined && input !== null && (input.startsWith('data:image'));
    };
    if (isValidInput(this.state.selectedLogo)) {
      formData.append("designs[header_attributes][logo]", this.state.selectedLogo.toString());
    }

    if (isValidInput(this.state.selectedFavicon)) {
      formData.append("designs[header_attributes][favicon]", this.state.selectedFavicon.toString());
    }
  }
manageBannerPart = async(item:any,index:any,formData:any)=>{
  const imageVariant = this.state.imageVariants[index]
  if (item.mobileBannerUpload && !item.mobileBannerUpload.startsWith('https://')) {
    formData.append(`designs[sections_attributes][${index}][mobile_banner]`, item.mobileBannerUpload);
    formData.append(`designs[sections_attributes][${index}][mobile_banner_variant_360]`, imageVariant.mobile_banner_variant_360);
    formData.append(`designs[sections_attributes][${index}][mobile_banner_variant_414]`, imageVariant.mobile_banner_variant_414);
    formData.append(`designs[sections_attributes][${index}][mobile_banner_variant_601]`, imageVariant.mobile_banner_variant_601);
    formData.append(`designs[sections_attributes][${index}][mobile_banner_filename]`, item.mobileBannerName ?? '');
  }

  if (item.desktopBannerUpload && !item.desktopBannerUpload.startsWith('https://')) {
    formData.append(`designs[sections_attributes][${index}][desktop_banner]`, item.desktopBannerUpload);
    formData.append(`designs[sections_attributes][${index}][desktop_banner_variant_768]`, imageVariant.desktop_banner_variant_768);
    formData.append(`designs[sections_attributes][${index}][desktop_banner_variant_1280]`,imageVariant.desktop_banner_variant_1280);
    formData.append(`designs[sections_attributes][${index}][desktop_banner_variant_1920]`,imageVariant.desktop_banner_variant_1920);
    formData.append(`designs[sections_attributes][${index}][desktop_banner_filename]`, item.desktopBannerName ?? '');
  }
  if (item.mobileBannerMetadata && isJson(item.mobileBannerMetadata)) {
    formData.append(`designs[sections_attributes][${index}][mobile_banner_metadata]`, item.mobileBannerMetadata);
  }
  if (item.desktopBannerMetadata && isJson(item.desktopBannerMetadata)) {
    formData.append(`designs[sections_attributes][${index}][desktop_banner_metadata]`, item.desktopBannerMetadata);
  }
  formData.append(`designs[sections_attributes][${index}][banner_url]`, item.bannerUrl ?? '');
  formData.append(`designs[sections_attributes][${index}][banner_background_colour]`, item.bannerBackgroundColour);
  formData.append(`designs[sections_attributes][${index}][banner_position]`, item.bannerPosition); 
  formData.append(`designs[sections_attributes][${index}][template_id]`, item.templateId); 
} 

manageCategoryPart=(item:any,index:any,formData:any)=>{
  if (item.sectionName) {
    formData.append(`designs[sections_attributes][${index}][section_name]`, item.sectionName);
  formData.append(`designs[sections_attributes][${index}][template_id]`, item.templateId); 

  }
  if (item.metadata) {
    item.metadata.isOpen = false;
    formData.append(`designs[sections_attributes][${index}][metadata]`, JSON.stringify(item.metadata));
  }
}


manageTestimonialPart=(item: SectionDataInterfaceType,index: number,formData: { append: (arg0: string, arg1: string) => void; })=>{
  if (item.sectionName) {
    formData.append(`designs[sections_attributes][${index}][section_name]`, item.sectionName);
  }
  if (item.metadata) {
    item.metadata.isOpen = false;
    item.metadata.isTestimonial = item.metadata.isTestimonial;
    item.metadata.testimonialName = item.metadata.testimonialName;
    formData.append(`designs[sections_attributes][${index}][metadata]`, JSON.stringify(item.metadata));
  }
};

manageLocationPart=(item: SectionDataInterfaceType,index: number,formData: { append: (arg0: string, arg1: string) => void; })=>{
  if (item.sectionName) {
    formData.append(`designs[sections_attributes][${index}][section_name]`, item.sectionName);
  }
  if (item.metadata) {
    item.metadata.isOpen = false;
    item.metadata.isLocation = item.metadata.isLocation;
    item.metadata.locationName = item.metadata.locationName;
    formData.append(`designs[sections_attributes][${index}][metadata]`, JSON.stringify(item.metadata));
  }
};

  manageformDataForUpdateData = (formData:any)=>{
    this.props.parentStates.SectionData.forEach((item, index) => {
      const sectionType = item.type?.toLowerCase();
      if (sectionType) {
        formData.append(`designs[sections_attributes][${index}][section_type]`, sectionType);
        formData.append(`designs[sections_attributes][${index}][is_deleted]`, item.is_deleted.toString());
        if (item.metadata && item.metadata.sectionIdToSend) {
          formData.append(`designs[sections_attributes][${index}][id]`, item.metadata.sectionIdToSend as string);
        }
        switch (sectionType) {
          case 'banner':
           this.manageBannerPart(item,index,formData)
            break;
          case 'service_display':
            this.manageCategoryPart(item,index,formData)
          break;
            case 'staff_display':
            this.manageCategoryPart(item,index,formData)
          break;
            case "testimonial":
              this.manageTestimonialPart(item,index,formData)
            break;
            case "location":
              this.manageLocationPart(item,index,formData)
            break;
          default:
            break;
        }
      }
    });
  }

  updateSectionState = (selectionName: string) => {
    this.props.updateSelectedSection(selectionName);
  }

  updateExpandedSection = (selectionName: string) => {
    this.props.updateExpandedSection(selectionName);
  }

  updateFooterCopyright = () => {
    if (this.props.footerCopyRight == "" && this.props.parentStates.storeName != "") {
      let copyrithText = `Copyright @ ${new Date().getFullYear()} ${this.props.parentStates?.storeName}. All rights reserved`
      this.props.updateFooterCopyRight(copyrithText)
      // this.setState({ copyRight: copyrithText })
    }
  }

  generateRandomString(length:number) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(randomValues[i] % characters.length);
    }
    return result;
}

  handleNavigationItemOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { navigationItems } = this.state;
    navigationItems.nevigationItem1 = event.target.value;
    this.setState({ navigationItems: navigationItems }, () => {
      this.props.updateNavigationName(navigationItems)
    })
    
    this.handleToaster(navigationItems.nevigationItem1, this.state.manageToster.nevigationItem1);
  }

  handleNavigationItemTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { navigationItems } = this.state;
    navigationItems.nevigationItem2 = event.target.value;
    this.setState({ navigationItems: navigationItems }, () => {
      this.props.updateNavigationName(navigationItems)
    });

    this.handleToaster(navigationItems.nevigationItem2, this.state.manageToster.nevigationItem2);
  }

  handleNavigationItemThree = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { navigationItems } = this.state;
    navigationItems.nevigationItem3 = event.target.value;
    this.setState({ navigationItems: navigationItems }, () => {
      this.props.updateNavigationName(navigationItems)
    });

    this.handleToaster(navigationItems.nevigationItem3, this.state.manageToster.nevigationItem3);
  }

  handleToaster = (newData: string, oldData: string) => {
    if( newData != oldData ) {
      this.openToastChange();
    } 
    else {
      this.closeToastChange();
    }
  }

  toggleTestimonial = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const trigger = event.target.checked;
      if (trigger === this.state.showTestimonial) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    this.props.handleTestimonialNameEnabled(trigger, index)
  };

  toggleLocation = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const trigger = event.target.checked
      if (trigger === this.state.showLocation) {
        this.closeToastChange()
      } else {
        this.openToastChange()
      }
    this.props.handleLocationNameEnabled(trigger, index)
  };

  handleBackgroundColorChange = (color: string, curIndex: number) => {
    document.documentElement.style.setProperty('--primary-color', color);
    this.setState({
      bannerBackgroundColor: color
    }, this.openToastChange)
    this.props.handleBannerBackgroundColor(color, curIndex);
  };

  hanldeBannerPosition = (position: string, curIndex: number) => {
      this.setState({
        bannerPosition: position
      })
      this.openToastChange();
    this.props.handleBannerPosition(position, curIndex)
  };

  reorder = (list: SectionDataInterfaceType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = this.reorder(
      this.props.parentStates.SectionData,
      result.source.index,
      result.destination.index
    );

    this.props.updatedArray(updatedItems);
    this.saveChangesConditions();
  };

  handleModalTitle = () => {
    return this.state.uploadModalType === "logo" ? (this.state.isLogoUploaded ? "Edit Logo" : configJSON.addLogoText) : configJSON.addFaviconText;
  };

  testimonialErrorCheck = (sectionName: string | undefined) => {
    if(sectionName == ""){
      return true
    }
    return false
  } 

  getHeaderTemplate = async (param: string) => {
    this.props.showLoader();
    await this.getDefaultColors()
    this.getHeaderTemplateCallId = this.apiCallHandle(this.state.token, `${configJSON.headerTemplateApiEndPoint}=${param}`, configJSON.getApiMethod);
  };

  handleHeaderResponse = (responseJson: HeaderTemplate) => {
    this.setState({ 
      headerTemplate: responseJson.data
    });
    this.props.hideLoader();
  }

  handleHeaderSelect = (name: string, id: string) =>{
    this.setState({selectHeader: name, templeteId: id})
  };
  
  handleHeaderThemeApply =() => {
    this.setState({isHeaderOpen: false, selectedHeader: this.state.selectHeader})
    this.openToastChange();
    this.props.handleHeaderTemplate(this.state.selectHeader);
  };

  handleHeaderCancle =() => {
    this.setState({isHeaderOpen: false, selectedHeader: "", selectHeader: ""})
  };

  handleEditHeader = () => {
    this.setState({ isHeaderOpen: true});
    this.getHeaderTemplate("header");
  };

  selectCatalogue = (catalogueIndex:number)=>{
    this.setState({selectedCatalogue: catalogueIndex});
  };

  closeModal = () => {
    this.setState({isBannerOpen: false});
  }

  handleCloseBannerModal = (iconType: string,templateId?:number) => {
    const iconTypeMapping: Record<string, number> = {
      banner: 0,
      staff_display: 1,
      service_display: 2,
    };
  
    if (iconType in iconTypeMapping) {
      this.setState(
        { isBannerOpen: !this.state.isBannerOpen, selectedSection:iconType, activeNumber: iconTypeMapping[iconType],
          selectedCatalogue:templateId||0
         },
        () => this.getHeaderTemplate(iconType)
      );
    }
  };
  

  handleAddSectionBanner = () => {
    // headerTemplate
    let template= this.state.headerTemplate.find(item => Number(item.id) === this.state.selectedCatalogue);
    this.setState({ isBannerOpen: false }, () => this.openToastChange());
    this.props.handleBannerTemplate(this.state.selectedCatalogue,template, this.state.selectedDropdownIndex);
    this.updateSectionState(`section${this.state.selectedDropdownIndex}Unique`)
  };
  // Customizable Area End
}

export default ContactInformationController; 